// Enums
export enum BondTransactionActionEnum {
  UNSPECIFIED = 'ACTION_UNSPECIFIED',
  BUY = 'ACTION_BUY',
  SELL = 'ACTION_SELL',
}

export enum BondTaxPreviewTypeEnum {
  UNSPECIFIED = 'TAX_PREVIEW_UNSPECIFIED',
  SELL = 'TAX_PREVIEW_SELL',
  ORDER = 'TAX_PREVIEW_ORDER',
  HISTORY = 'TAX_PREVIEW_HISTORY',
}

export enum BondProductTypeEnum {
  UNSPECIFIED = 'PRODUCT_TYPE_UNSPECIFIED',
  FR = 'PRODUCT_TYPE_FR',
  SBN = 'PRODUCT_TYPE_SBN',
}

export enum BondOrderStatus {
  UNSPECIFIED = 'BOND_ORDER_STATUS_UNSPECIFIED',
  SUBMITTED = 'BOND_ORDER_STATUS_SUBMITTED',
  VERIFIED = 'BOND_ORDER_STATUS_VERIFIED',
  SUCCEEDED = 'BOND_ORDER_STATUS_SUCCEEDED',
  REJECTED = 'BOND_ORDER_STATUS_REJECTED',
}

// Reusable Entities
export interface BondsAmount {
  amount: number;
  percentage: number;
}

export interface BondProduct {
  type: BondProductTypeEnum;
  id: string;
  name: string;
  min_order: string;
  icon_url: string;
}

export interface BondFee {
  stamp_duty: number;
  capital_gain_tax: number;
  accrued_interest_tax: number;
  commission_fee: number;
  total_tax: number;
  tax_percent: number;
}

export interface BondOrderFee {
  accrued_interest_exclude_tax: number;
  accrued_interest_tax: number;
  capital_gain_tax: number;
  commission_fee: number;
  estimation_amount_exclude_tax: number;
  stamp_duty: number;
  tax_percent: number;
  total_tax: number;
}

export interface BondBank {
  id: string;
  name: string;
  color_hex: string;
}

export interface BondRDN {
  account_name: string;
  bank: BondBank;
  balance: number;
}

export interface BondPrice {
  deal: number;
  markup: number;
  buy: number;
  sell: number;
}

export interface BondAquisition {
  price: number;
  date?: Date;
  order_id: string;
}

export interface BondStatusTimeline {
  status: BondOrderStatus;
  occurred_at: Date;
}

export interface BondOrder {
  id: string;
  action: BondTransactionActionEnum;
  final_amount: number;
  price_rate: number;
  status: BondOrderStatus;
  ordered_at: Date;
  trade_unit: string;
  trade_amount: number;
  accrued_interest_amount: number;
  stamp_duty_amount: number;
  status_timeline: BondStatusTimeline[];
  product: BondProduct;
  fee: BondOrderFee;
  reject_reason?: {
    message: string;
    type: string;
  };
  realized_amount: number;
  seller_coupon: number;
  profit_loss_nett: BondsAmount;
  capital_gain_loss: number;
}

export interface BondCouponHistoryItem {
  amount: number;
  date: string;
  trans_product_id: string;
  coupon_type: string;
  coupon: {
    seller: number;
    user: number;
  };
  units: number;
}

export interface BondCouponHistoryByYear {
  year: string;
  items: BondCouponHistoryItem[];
}

export interface BondCouponHistory {
  total: number;
  history_count: string;
  histories: BondCouponHistoryByYear[];
  total_seller_coupon: number;
  total_user_coupon: number;
}

export interface BondProductMeta {
  maturity_date: string;
  coupon_rate: number;
  next_coupon_date: string;
  sell_price_rate: number;
}

export interface BondPortfolioProduct {
  identifier: string;
  label: string;
  name: string;
  icon_url: string;
  is_sharia: boolean;
  meta: BondProductMeta;
}

export interface BondsExchange {
  currency: string;
  exchange_rate: number;
}

export interface BondTransaction {
  invested_amount: number;
  market_value: number;
  accrued_interest: number;
  units: string;
  pending_units: string;
  pending_invested: number;
  pending_amount: number;
  total_portfolio: number;
  total_unit: string;
  price_rate: number;
  yield_rate: number;
  order_code: string;
  created_at: string;
  daily_accrued_interest: BondsAmount;
  capital_gain_loss: BondsAmount;
  profit_loss: BondsAmount;
  projected_return: BondsAmount;
  is_stable_earn: boolean;
}

export interface BondPortfolio {
  invested_amount: number;
  market_value: number;
  accrued_interest: number;
  units: string;
  pending_units: string;
  pending_invested: number;
  pending_amount: number;
  total_portfolio: number;
  total_units: string;
  yield_rate: number;
  daily_accrued_interest: BondsAmount;
  capital_gain_loss: BondsAmount;
  profit_loss: BondsAmount;
  projected_return: BondsAmount;
  price_rate: number;
  total_profit_loss: BondsAmount;
  seller_coupon: number;
}

export interface BondPortfolioWithProduct extends BondPortfolio {
  product: BondPortfolioProduct;
}

// Forms Body & Queries
export interface BondsBuyForm {
  product_id: string;
  unit: number;
  price_rate: number;
}

export interface BondsBuyPreviewQuery {
  product_id: string;
  unit: number;
}

export interface BondsSellForm extends BondsBuyForm {
  buy_order_id: string;
}

export interface BondsSellPreviewQuery extends BondsBuyPreviewQuery {
  acquisition_order_id: string;
}

// API Responses
export interface BondBuyPreviewResponse {
  product: BondProduct;
  unit: string;
  rdn: BondRDN;
  settlement_date: string;
  accrued_interest: number;
  buy_price_amount: number;
  invested_amount: number;
  price_rate: number;
  estimation_amount: number;
  total_amount: number;
  fee: {
    stamp_duty: number;
    tax: number;
  };
}

export interface BondSellPreviewResponse {
  action: BondTransactionActionEnum;
  available_units: number;
  units: number;
  sell_amount: number;
  accrued_interest: number;
  amount: number;
  profit_loss: number;
  price: BondPrice;
  fee: BondOrderFee;
  product: BondProduct;
  acquisition: BondAquisition;
  daily_accrued_interest: number;
  invested_amount: number;
  accrued_interest_exclude_tax: number;
  estimation_amount_exclude_tax: number;
  settlement_date: Date;
  next_coupon_date: Date;
  last_coupon_date: Date;
  estimation_sell_amount: number;
  estimation_disburse_amount: number;
  transaction_pnl: number;
  capital_gain_loss_nett: number;
  seller_coupon: number;
  total_realized_amount: number;
  total_realized_amount_percentage: number;
  profit_loss_nett: number;
  profit_loss_nett_percentage: number;
}

export interface BondOrderListResponse {
  orders: BondOrder[];
}

export interface BondOrderResponse {
  order: BondOrder;
}

export interface BondPortfolioListResponse extends BondPortfolio {
  portfolios: BondPortfolioWithProduct[];
}

export interface BondPortfolioDetailResponse extends BondPortfolio {
  product: BondPortfolioProduct;
  transactions: BondTransaction[];
  coupon_histories: BondCouponHistory;
}

export interface BondTaxModalState {
  accruedInterest: number;
  pl: number;
  amount: number;
  totalTax: number;
}
