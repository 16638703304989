import { parseCookies, destroyCookie } from 'nookies';

function getIsLoggedIn(ctx = null) {
  const parsedCookie = parseCookies(ctx);

  if (!parsedCookie || !parsedCookie.isLoggedIn) {
    return null;
  }

  return parsedCookie.isLoggedIn === 'true';
}

async function clearCookies() {
  const parsedCookie = await parseCookies();
  let keys = Object.keys(parsedCookie);
  keys = keys.filter((k) => k.includes('crisp') || k.includes('auth'));
  keys.forEach((k) => destroyCookie(k));
}

export default {
  getIsLoggedIn,
  clearCookies,
};
