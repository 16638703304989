import create from 'zustand/vanilla';
import { BondBlockerType, bondsErrorTypesMapping } from '../constants';

interface State {
  errorCode: string | number;
  errorMessage: string;
  errorType: string;
  blockerType?: BondBlockerType;
}

interface Action {
  setErrorCode: (
    code: string | number,
    message: string | null,
    type: string | null,
  ) => void;
  resetErrorCode: () => void;
}

const initialState = {
  errorCode: 0,
  errorMessage: '',
  errorType: '',
  blockerType: BondBlockerType.UNSPECIFIED,
};

const useBondError = create<State & Action>((set) => ({
  ...initialState,
  setErrorCode: (code, message = '', type = '') => {
    const blockerType = bondsErrorTypesMapping[type];

    set({
      errorCode: code,
      errorMessage: message,
      errorType: type,
      blockerType,
    });
  },
  resetErrorCode: () => {
    set(initialState);
  },
}));

export default useBondError;
