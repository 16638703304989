/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable import/named */
import { API_EXODUS_URL } from 'constants/api';
import requestExodusConfig from 'config/requestExodus';
import exodusInstance from 'lib/api/instances/exodus';
import { APIResponse } from '../../../@types/api-response';
import {
  CustomDataSource,
  SymbolWatchlist,
  Watchlist,
  WatchlistItem,
} from '../../../@types/watchlist';

const requestExodus = exodusInstance.defaultInstance;
const requestExodusNoAlert = exodusInstance.noAlert;

type WatchlistResponse<DataToken = any> = Promise<APIResponse<DataToken>>;

// Exodus API
const WATCHLIST_EXODUS_URL = `${API_EXODUS_URL}/watchlist`;
const COMPANY_EXODUS_WATCHLIST = `${WATCHLIST_EXODUS_URL}/company`;
const COMPANY_EXODUS_WATCHLIST_SUGGESTION = `${WATCHLIST_EXODUS_URL}/suggestion/company`;
const PEOPLE_EXODUS_WATCHLIST = `${WATCHLIST_EXODUS_URL}/people`;

const postFollowUser = (userid): WatchlistResponse =>
  requestExodus.post(`${PEOPLE_EXODUS_WATCHLIST}/item`, {
    user_id: userid,
  });

const postUnfollowUser = (userid): WatchlistResponse =>
  requestExodus.delete(`${PEOPLE_EXODUS_WATCHLIST}/${userid}/item`);

/**
 * Set notification alert for certain user
 * @param {number} userid
 * @returns {APIResponse}
 */
const setNotificationAlert = (userid) =>
  requestExodus.put(`${PEOPLE_EXODUS_WATCHLIST}/alert`, { user_id: userid });

const getPeopleFollowers = (userid, params): WatchlistResponse =>
  requestExodusNoAlert.get(`${PEOPLE_EXODUS_WATCHLIST}/${userid}/followers`, {
    params,
  });

// company watchlist
const getAllWatchlist = (
  companyid?: string | number,
  withTotal?: boolean,
  page = 1,
  limit = 500,
): WatchlistResponse<Watchlist[]> => {
  // eslint-disable-next-line no-undef
  const params: Record<string, any> = {
    page,
    limit,
  };

  if (companyid) {
    params.company_id = companyid;
  }

  if (withTotal) {
    params.with_total = withTotal;
  }

  return requestExodusNoAlert.get(`${WATCHLIST_EXODUS_URL}`, { params });
};

const getDetailWatchlist = (
  watchlistid,
  params: Record<string, any> = {},
): WatchlistResponse<Watchlist> => {
  const bodyParams = {
    page: 1,
    limit: 500,
    nochart: 1,
    ...params,
  };
  return requestExodusConfig(false).get(
    `${WATCHLIST_EXODUS_URL}/${watchlistid}`,
    {
      params: bodyParams,
    },
  );
};

const getSymbolWatchlist = (watchlistId: string): WatchlistResponse<SymbolWatchlist> =>
  requestExodusNoAlert.get(`${WATCHLIST_EXODUS_URL}/${watchlistId}/symbols`);

const getCompanyFollowers = (companyid, params): WatchlistResponse =>
  requestExodusNoAlert.get(`${COMPANY_EXODUS_WATCHLIST}/${companyid}/followers`, {
    params,
  });

const getCompanySuggestions = (
  watchlistid: number,
  limit = 100,
): WatchlistResponse =>
  requestExodusNoAlert.get(COMPANY_EXODUS_WATCHLIST_SUGGESTION, {
    params: {
      watchlist_id: watchlistid,
      limit,
    },
  });

const postFollowCompany = (
  watchlistid: string | number,
  companyid: string | number,
): WatchlistResponse<WatchlistItem[]> =>
  requestExodus.post(`${WATCHLIST_EXODUS_URL}/${watchlistid}/company/item`, {
    company_id: Number(companyid),
  });

const postUnfollowCompany = (
  watchlistid: string | number,
  companyid: string | number,
): WatchlistResponse<WatchlistItem> =>
  requestExodus.delete(
    `${WATCHLIST_EXODUS_URL}/${watchlistid}/company/${companyid}/item`,
  );

// core watchlist
const createNewWatchlist = (
  name: string,
  description?: string,
): WatchlistResponse<Watchlist> =>
  requestExodus.post(`${WATCHLIST_EXODUS_URL}`, { name, description });

const deleteWatchlistById = (
  watchlistid: string | number,
): WatchlistResponse<any> =>
  requestExodus.delete(`${WATCHLIST_EXODUS_URL}/${watchlistid}`);

const updateWatchlist = (
  watchlistid: string | number,
  name: string,
  description?: string,
): WatchlistResponse<Watchlist> =>
  requestExodus.put(`${WATCHLIST_EXODUS_URL}/${watchlistid}`, {
    name,
    description,
  });

// financial metrics
const addFinancialMetrics = (
  watchlistid: string,
  fitemid: string,
): WatchlistResponse<CustomDataSource> => {
  const req = requestExodusConfig(false);
  return req.post(`${WATCHLIST_EXODUS_URL}/${watchlistid}/column`, {
    fitemid: Number(fitemid),
  });
};

const removeFinancialMetrics = (
  watchlistid: string,
  fitemid: string,
): WatchlistResponse<CustomDataSource> =>
  requestExodus.delete(
    `${WATCHLIST_EXODUS_URL}/${watchlistid}/column/${fitemid}`,
  );

const getFinancialValues = (
  watchlistid: string,
  fitemid: string,
): WatchlistResponse<CustomDataSource> =>
  requestExodusConfig(false).get(
    `${WATCHLIST_EXODUS_URL}/${watchlistid}/column`,
    {
      params: { fitemid },
    },
  );

const reorderFinancialValues = (
  watchlistid: string,
  fitemid: string,
): WatchlistResponse =>
  requestExodus.put(`${WATCHLIST_EXODUS_URL}/${watchlistid}/column/reorder`, {
    fitemid,
  });

const getPeopleFollowing = (userid, params): WatchlistResponse =>
  requestExodusNoAlert.get(`${PEOPLE_EXODUS_WATCHLIST}/${userid}/following`, {
    params,
  });

const getCompanyFollowerInfo = (symbol: string): Promise<APIResponse<any>> =>
  requestExodusNoAlert.get(`${COMPANY_EXODUS_WATCHLIST}/follower-info`, {
    params: {
      symbols: symbol,
    },
  });

// Exports ---------------------------------------------------------------------
export default {
  // people watchlist
  postUnfollowUser,
  postFollowUser,
  setNotificationAlert,
  getPeopleFollowers,
  getPeopleFollowing,

  // company watchlist
  postFollowCompany,
  postUnfollowCompany,
  getCompanyFollowers,
  getCompanySuggestions,
  getCompanyFollowerInfo,

  // new watchlist fetch
  getAllWatchlist,
  getDetailWatchlist,
  getSymbolWatchlist,

  // core watchlist
  createNewWatchlist,
  deleteWatchlistById,
  updateWatchlist,

  // financial metrics
  addFinancialMetrics,
  removeFinancialMetrics,
  getFinancialValues,
  reorderFinancialValues,
};
