import { UserTargetAPI } from 'constants/trading';

export const getTargetAPI = (): UserTargetAPI => {
  const target = localStorage.getItem('securitiesTarget') as UserTargetAPI;
  return target || UserTargetAPI.LEGACY;
};

export const setTargetAPI = (
  target: UserTargetAPI = UserTargetAPI.LEGACY,
): UserTargetAPI => {
  localStorage.setItem('securitiesTarget', target);
  return target;
};

export const isRedirectToNewCore = (): boolean =>
  getTargetAPI() === UserTargetAPI.NEW_CORE;

export const generateUiRef = () => {
  let result = '';
  const length = 13;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  const charactersLength = characters.length;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return `W${Date.now()}${result}`;
};

/**
 *
 * @param balanceValue - balance value number
 * @param fraction - fraction number
 * @returns
 * @example
 * getRoundDownValue(12345, 2) => 12345
 * getRoundDownValue(12345.6789, 2) => 12345.67
 * getRoundDownValue(12345.6789, 3) => 12345.678
 */
export const getRoundDownValue = (balanceValue: number, fraction: number) => {
  const multiplier = 10 ** fraction;
  const roundedValue = Math.floor(balanceValue * multiplier) / multiplier;

  const stringValue = balanceValue.toString();
  const decimalIndex = stringValue.indexOf('.');

  if (decimalIndex !== -1) {
    const truncatedValue = stringValue.slice(0, decimalIndex + fraction + 1);
    return parseFloat(truncatedValue);
  }

  return roundedValue;
};
