import { string, number } from 'prop-types';

const schema = {
  username: '',
  email: '',
  fullname: '',
  avatar: '',
  password: 0,
  verified: 0,
  official: 0,
  privilege: 0,
  id: '',
  watchlist_id: 0,
  country: '',
  exchange: '',
};

const propTypes = {
  username: string,
  email: string,
  fullname: string,
  avatar: string,
  password: number,
  verified: number,
  official: number,
  privilege: number,
  id: string,
  watchlist_id: number,
  country: string,
  exchange: string,
};

export default { schema, propTypes };
