import { ACCOUNT_TYPE } from 'global/TradingModal/RealTradingModal/types';
import create from 'zustand';
import securitiesAPI from 'lib/api/securities';
import { postAuthRefresh, postSwitchVirtual } from 'lib/api/authSecurities';
import tradingAPI from 'lib/api/trade';
import multiPortoAPI from 'lib/api/multi-porto';
import useMultiPortfolioStore from 'lib/stores/multiPorto';
import noticeError from 'utils/logger';
import { setTargetAPI } from 'features/securities/utils';
import securitiesLocalStorage from 'utils/securitiesLocalStorage';
import { LoginRealResponse, AccountTypeEnum, SwitchLoginRealResponse } from '../types/api/auth';

interface State {
  accountType: ACCOUNT_TYPE | null;
  accessToken: string | null;
  refreshToken: string | null;
  type: AccountTypeEnum | null;
  accountNumber: string | null;
  mainAccountNumber: string | null;
}

interface Action {
  resetStore: () => void;
  switchAccountType: (type: State['accountType']) => void;
  loadLoginReal: (pin: string) => Promise<LoginRealResponse>;
  loadLoginVirtual: () => Promise<void>;
  loadLogout: () => Promise<void>;
  loadRefresh: () => Promise<void>;
  loadSwitchLoginReal: (accountNumber: string) => Promise<SwitchLoginRealResponse>;
}

const initialState: State = {
  accountType: securitiesLocalStorage.getTradingAccountType(),
  accessToken:
    securitiesLocalStorage.getSecuritiesToken().securitiesAccessToken,
  refreshToken:
    securitiesLocalStorage.getSecuritiesToken().securitiesRefreshToken,
  type: securitiesLocalStorage.getSecuritiesAccountInfo()
    .accountType as AccountTypeEnum,
  accountNumber:
    securitiesLocalStorage.getSecuritiesAccountInfo().accountNumber,
  mainAccountNumber:
    securitiesLocalStorage.getSecuritiesAccountInfo().mainAccountNumber,
};

const useSecuritiesCredentialStore = create<State & Action>((set, get) => ({
  ...initialState,
  resetStore: () =>
    set({
      accountType: null,
      accessToken: null,
      refreshToken: null,
      type: null,
      accountNumber: null,
      mainAccountNumber: null,
    }),
  switchAccountType: (accountType) => set({ accountType }),
  loadLoginReal: async (pin: string) => {
    try {
      const loginTokenRes = await securitiesAPI.getAuthToken();
      const { token: loginToken, target } = loginTokenRes?.data?.data ?? {};
      if (!loginToken) {
        throw Error('Get Login Token failed', {
          cause: { type: 'GET_AUTH_TOKEN' },
        });
      }

      setTargetAPI(target);

      const validatePinRes = await securitiesAPI.postAuthPIN(loginToken, pin);
      const { data, error_type, message } = validatePinRes?.data ?? {};
      const { access_token: accessToken, refresh_token: refreshToken, account, main_account } =
        data ?? {};

      const { type, number } = account ?? {};

      if (error_type || !accessToken || !refreshToken) {
        throw Error(message || 'Attemp to post Auth PIN failed', {
          cause: { type: 'POST_AUTH_PIN', error_type, message },
        });
      }

      useMultiPortfolioStore?.getState()?.setActiveAccount(number);

      securitiesLocalStorage.saveSecuritiesToken({
        securitiesAccessToken: accessToken,
        securitiesRefreshToken: refreshToken,
      });

      securitiesLocalStorage.saveSecuritiesAccountInfo({
        accountType: account?.type,
        accountNumber: account?.number,
        mainAccountNumber: main_account?.number,
      });

      set({
        accessToken,
        refreshToken,
        accountType: ACCOUNT_TYPE.REAL,
        type,
        accountNumber: number,
        mainAccountNumber: main_account?.number,
      });
      return { loginToken, target, accessToken, refreshToken, type, number };
    } catch (error) {
      noticeError(error, {
        event: 'useSecuritiesCredentialStore@loadLoginReal',
        errCause: error?.cause,
        errType: error?.response?.data?.error_type,
        errMessage: error?.response?.data?.message,
      });
      throw error;
    }
  },
  loadLoginVirtual: async () => {
    try {
      const res = await postSwitchVirtual();
      if (!res?.data) {
        throw new Error('Attempt to switch to virtual failed');
      }

      securitiesLocalStorage.switchToVirtualTrading();
      set({ accountType: ACCOUNT_TYPE.VIRTUAL });
    } catch (error) {
      noticeError(error, {
        event: 'useSecuritiesCredentialStore@loginVirtual',
        errType: error?.response?.data?.error_type,
        errMessage: error?.response?.data?.message,
      });
      throw error;
    }
  },
  loadLogout: async () => {
    const { accountType, accessToken, resetStore } = get();
    try {
      if (accountType === ACCOUNT_TYPE.REAL && accessToken) {
        const res = await tradingAPI.logout();
        if (res?.data?.error) {
          throw new Error('Attempt to logout trading failed');
        }
      }
    } catch (error) {
      noticeError(error, {
        event: 'useSecuritiesCredentialStore@logout',
        errCause: error?.cause,
      });
    }
    resetStore();
    securitiesLocalStorage.logoutTrading();
  },
  loadRefresh: async () => {
    const { refreshToken: currentRefreshToken, loadLogout: logout } = get();
    try {
      if (!currentRefreshToken) {
        throw new Error('Securities refresh token does not exist');
      }

      const res = await postAuthRefresh(currentRefreshToken);
      if (!res?.data || res.data.error) {
        throw new Error('Attemp to refresh token failed');
      }

      const { access_token: accessToken, refresh_token: refreshToken } =
        res.data.data ?? {};

      securitiesLocalStorage.saveSecuritiesToken({
        securitiesAccessToken: accessToken,
        securitiesRefreshToken: refreshToken,
      });
      set({ accessToken, refreshToken });
    } catch (error) {
      logout();
      noticeError(error, {
        event: 'useSecuritiesCredentialStore@refresh',
        errCause: error?.cause,
      });
      throw error;
    }
  },
  loadSwitchLoginReal: async (accountNumber: string) => {
    try {
      const validatePinRes = await multiPortoAPI.postSwitchAccount(accountNumber);
      const { data, error_type, message } = validatePinRes?.data ?? {};
      const { access_token: accessToken, refresh_token: refreshToken, account, main_account } =
        data ?? {};

      const { type, number } = account ?? {};

      if (error_type || !accessToken || !refreshToken) {
        throw Error(message || 'Attemp to Switch Sub Account failed', {
          cause: { type: 'POST_SWITCH_SUB_ACCOUNT', error_type, message },
        });
      }

      securitiesLocalStorage.saveSecuritiesToken({
        securitiesAccessToken: accessToken,
        securitiesRefreshToken: refreshToken,
      });

      securitiesLocalStorage.saveSecuritiesAccountInfo({
        accountType: account?.type,
        accountNumber: account?.number,
        mainAccountNumber: main_account?.number,
      });

      const tokenUpdateEvent = new CustomEvent('updateMultiPorto', {
        detail: { number },
      });
      window.dispatchEvent(tokenUpdateEvent);

      const channel = new BroadcastChannel('updateMultiPorto');
      channel.postMessage({ number });

      set({
        accessToken,
        refreshToken,
        accountType: ACCOUNT_TYPE.REAL,
        type,
        accountNumber: number,
        mainAccountNumber: main_account?.number,
      });
      return { accessToken, refreshToken, type, number };
    } catch (error) {
      noticeError(error, {
        event: 'useSecuritiesCredentialStore@loadLoginReal',
        errCause: error?.cause,
        errType: error?.response?.data?.error_type,
        errMessage: error?.response?.data?.message,
      });
      throw error;
    }
  },
}));

export default useSecuritiesCredentialStore;
