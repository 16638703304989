import { API_TRADING_URL, API_TRADING_URL_V2 } from 'constants/api';
import requestRefreshTradingConfig from 'config/requestRefreshTrading';
import { isRedirectToNewCore } from 'features/securities/utils';
import { AuthRefreshResponse } from 'global/Securities/types/api/auth';
import { APIResponse } from '../../../@types/api-response';
import exodusInstance from './instances/exodus';

const { defaultInstance } = exodusInstance;
const requestRefreshTrading = requestRefreshTradingConfig();

export const postSwitchVirtual = (): Promise<APIResponse> =>
  defaultInstance.post('virtualtrading/account/activate');

export const postAuthRefresh = (
  refreshToken,
): Promise<APIResponse<AuthRefreshResponse>> =>
  requestRefreshTrading.post(
    `${
      isRedirectToNewCore() ? API_TRADING_URL_V2 : API_TRADING_URL
    }/auth/refresh`,
    {
      refresh_token: refreshToken,
    },
  );
