import { assetPrefix } from 'constants/app';
import { ChatRoomType } from './types';

export const NOTIF_GROUP_CHAT = 'NOTIF_GROUP_CHAT';
export const NOTIF_SETTING_KEY_DN = 'NOTIF_SETTING_KEY_DN';

export const DATA_WS_LOGGER = {
  MODULE_NAME: 'WS Social',
  LOG_LEVEL: {
    LOG: 'log',
    WARN: 'warn',
    ERROR: 'error',
  },
  ACTION: {
    OPEN_CONNECTION: 'Open Connection',
    INITIALIZE_NEW_CONNECTION: 'Initialize New Connection',
    INCOMING_MESSAGE: 'Incoming Message',
    ERROR_CONNECTION: 'Error Connection',
    CLEAR_PING_INTERVAL: 'Clear Interval Ping',
    CLOSE_PREVIOUS_CONNECTION: 'Close Previous Connection',
    CLOSE_CONNECTION: 'Close Connection',
    CHECK_ALLOWED_RECONNECTION: 'Check Allowed Reconnection',
    SUBSCRIBE_TO_CHAT_ROOMS: 'Subscribe to Chat Rooms Channel',
    UNSUBSCRIBE_TO_CHAT_ROOMS: 'Unsubscribe to Chat Rooms Channel',
    SUBSCRIBE_TO_ROOM_LIST: 'Subscribe to Room List Channel',
    UNSUBSCRIBE_TO_ROOM_LIST: 'Unsubscribe to Room List Channel',
    DISCONNECT: 'Disconnect',
  },
};

export const WS_SOCIAL_VERSION = 'v1';

export const socketV2Key = {
  social: 'social',
};

export const WEBSOCKET_INTERVAL_PING_TIME = 20000; // 20s
export const WEBSOCKET_TOTAL_RECONNECTION = 3;
export const WEBSOCKET_WS_SOCIAL_PING_INTERNAL =
  'WEBSOCKET_WS_SOCIAL_PING_INTERNAL';

export const DEBUG_WS_SOCIAL = 'DEBUG_WS_SOCIAL';

export const WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION = {
  NAME: 'WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION',
  ALLOWED: false,
  NOT_ALLOWED: true,
};

export const MESSAGE_LISTENER_TAG = {
  OPEN_CONNECTION: '@socketSocial/openConnection',
};

export const WEBSOCKET_READYSTATE = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const SEND_MESSAGE_TYPE = {
  TYPE_PING: 'TYPE_PING',
  TYPE_ERROR: 'TYPE_ERROR',
  TYPE_BROADCAST: 'TYPE_BROADCAST',
  TYPE_SUBSCRIBE: 'TYPE_SUBSCRIBE',
  TYPE_UNSUBSCRIBE: 'TYPE_UNSUBSCRIBE',
};

export const EVENT_TYPE_BROADCAST = {
  TYPE_CHAT_MESSAGE: 'TYPE_CHAT_MESSAGE',
  TYPE_CHAT_ROOM_INFO: 'TYPE_CHAT_ROOM_INFO',
  TYPE_CHAT_TYPING_INDICATOR: 'TYPE_CHAT_TYPING_INDICATOR',
};

export const SOCKET_SOCIAL_REDUCERS = {
  SOCKET_SOCIAL_CONNECT: 'SOCKET_SOCIAL_CONNECT',
  SOCKET_SOCIAL_DISCONNECT: 'SOCKET_SOCIAL_DISCONNECT',
  SOCKET_SOCIAL_STORE_SUBSCRIBED_ROOMS_ID:
    'SOCKET_SOCIAL_STORE_SUBSCRIBED_ROOMS_ID',
  SOCKET_SOCIAL_CLEAN_SUBSCRIBED_ROOMS_ID:
    'SOCKET_SOCIAL_CLEAN_SUBSCRIBED_ROOMS_ID',
  SOCKET_SOCIAL_CLEAN_ALL_SUBSCRIBED_ROOMS_ID:
    'SOCKET_SOCIAL_CLEAN_ALL_SUBSCRIBED_ROOMS_ID',
};

export const SOCKET_SOCIAL_ERROR = {
  500: 'WebSocket connection to server failed',
};

export const RoomTypeFromPayload = {
  PRIVATE: 'ROOM_TYPE_PRIVATE_CHAT',
  REQUESTED: 'ROOM_TYPE_REQUESTED_CHAT',
  PERSONAL: <ChatRoomType>'ROOM_TYPE_PERSONAL',
  GROUP: <ChatRoomType>'ROOM_TYPE_GROUP',
  BROADCAST: <ChatRoomType>'ROOM_TYPE_BROADCAST',
};

export const memberRole = {
  MEMBER_ROLE_ADMIN: 'MEMBER_ROLE_ADMIN',
  MEMBER_ROLE_NORMAL: 'MEMBER_ROLE_NORMAL',
  MEMBER_ROLE_UNSPECIFIED: 'MEMBER_ROLE_UNSPECIFIED',
};

export const DEFAULT_CHAT_ROOM_ID = 0;

export const CHAT_CONTENT_CONTAINER_ID = 'chat-content-container';
export const CHAT_BUBBLE_ID = 'chat-bubble';
export const CHAT_UNREAD_EVENT_ID = 'chat-unread-event';
export const MAX_AVATAR_IMAGE_SIZE = 2097152;

export const MAX_GROUP_TITLE_LENGTH = 30;
export const MAX_GROUP_DESCRIPTION_LENGTH = 255;
export const MAX_GROUP_MEMBER_DEFAULT = 100;

export const CHAT_RIGHT_SIDEBAR_INFO = 'chat-right-sidebar-container';
export const CHAT_RIGHT_SIDEBAR_INFO_HEADER = 'chat-right-sidebar-info-header';
export const CHAT_RIGHT_SIDEBAR_SEARCH_HEADER =
  'chat-right-sidebar-search-header';

export const LINK_TYPE_MESSAGE_CONTENT = {
  LINK_TYPE_USER: 'LINK_TYPE_USER',
  LINK_TYPE_SYMBOL: 'LINK_TYPE_SYMBOL',
  LINK_TYPE_URL: 'LINK_TYPE_URL',
  LINK_TYPE_KNOWN_URL: 'LINK_TYPE_KNOWN_URL',
};

export const FORMAT_TYPE_MESSAGE_CONTENT = {
  TEXT_FORMAT_REGULAR: 'TEXT_FORMAT_REGULAR',
  TEXT_FORMAT_BOLD: 'TEXT_FORMAT_BOLD',
  TEXT_FORMAT_ITALIC: 'TEXT_FORMAT_ITALIC',
  TEXT_FOTMAT_UNDERLINE: 'TEXT_FOTMAT_UNDERLINE',
};

export const MAX_TIPPING_AMOUNT = 10000000;

export const MAX_MESSAGE_PER_PAGE = 50;

export const TOTAL_ROOM_PER_PAGE = 25;

export const TOOLTIP_CREATE_GROUPCHAT =
  'Buat Grup Chat dan diskusi saham dengan teman-temanmu!';
export const TOOLTIP_REPLY_FORWARD = 'Klik untuk reply atau forward chat';
export const LS_TOOLTIP_CREATE_GROUP_CHAT = 'tooltipChatCreateGroup';
export const LS_TOOLTIP_REPLY_FORWARD = 'tooltipChatReplyForward';

export const SOURCE_CHAT_ROOM_VIEWED = [
  'Chat List Page',
  'Push Notification',
  'User Profile',
  'Chat Search',
  'Inbox Chat',
  'Create New Chat',
  'Group Chat Bottom Sheet',
] as const;

export const MAX_IMAGE_FILE_SIZE_MB = 5;
export const ERROR_MAX_IMAGE_FILE_SIZE = `File is too large and cannot be uploaded. The maximum size for each file is ${MAX_IMAGE_FILE_SIZE_MB}MB`;
export const MAX_DOCUMENT_FILE_SIZE_MB = 10;
export const ERROR_MAX_DOCUMENT_FILE_SIZE = `File you tried adding is larger than the ${MAX_DOCUMENT_FILE_SIZE_MB} MB limit.`;

export const MAX_CHAT_UNREAD_COUNT = 99;

export const StateChatList = {
  ROOM_STATE_UNSPECIFIED: 'ROOM_STATE_UNSPECIFIED',
  ROOM_STATE_ACCEPTED: 'ROOM_STATE_ACCEPTED',
  ROOM_STATE_INVITED: 'ROOM_STATE_INVITED',
};

export const NOTIF_TYPE = [
  {
    // personal chat accepted
    type: RoomTypeFromPayload.PERSONAL,
    state: StateChatList.ROOM_STATE_ACCEPTED,
    notifType: 'NOTIF_TYPE_INBOX',
  },
  {
    // personal chat requested
    type: RoomTypeFromPayload.PERSONAL,
    state: StateChatList.ROOM_STATE_INVITED,
    notifType: 'NOTIF_TYPE_MESSAGE_REQUEST',
  },
  {
    type: RoomTypeFromPayload.REQUESTED,
    state: StateChatList.ROOM_STATE_INVITED,
    notifType: 'NOTIF_TYPE_MESSAGE_REQUEST',
  },
  {
    // group chat accepted
    type: RoomTypeFromPayload.GROUP,
    state: StateChatList.ROOM_STATE_ACCEPTED,
    notifType: 'NOTIF_TYPE_GROUPCHAT',
  },
  {
    // group chat invited
    type: RoomTypeFromPayload.GROUP,
    state: StateChatList.ROOM_STATE_INVITED,
    notifType: 'NOTIF_TYPE_MESSAGE_REQUEST',
  },
  {
    // Broadcast Message
    type: RoomTypeFromPayload.BROADCAST,
    state: StateChatList.ROOM_STATE_ACCEPTED,
    notifType: 'NOTIF_TYPE_INBOX',
  },
];

export const GROUP_SHARED_LINK_INVITATION = {
  METATAG: {
    DEFAULT_NAME: 'Stockbit Group Invite',
    DEFAULT_IMAGE: `${assetPrefix}/icon/stockbit-logo-black-bg-white.png`,
  },
  MAX_WORDS_DESCRIPTION: 120,
};

export const ATTACHMENT_TYPE = {
  ATTACHMENT_TYPE_UNSPECIFIED: 'ATTACHMENT_TYPE_UNSPECIFIED',
  ATTACHMENT_TYPE_GIF: 'ATTACHMENT_TYPE_GIF',
  ATTACHMENT_TYPE_PHOTO: 'ATTACHMENT_TYPE_PHOTO',
  ATTACHMENT_TYPE_POST: 'ATTACHMENT_TYPE_POST',
  ATTACHMENT_TYPE_PDF: 'ATTACHMENT_TYPE_PDF',
  ATTACHMENT_TYPE_STICKER: 'ATTACHMENT_TYPE_STICKER',
  ATTACHMENT_TYPE_SHARETRADE: 'ATTACHMENT_TYPE_SHARETRADE',
};

export const MESSAGE_BODY_TYPE = {
  MESSAGE_BODY_TYPE_UNSPECIFIED: 'MESSAGE_BODY_TYPE_UNSPECIFIED',
  MESSAGE_BODY_TYPE_CONVERSATION: 'MESSAGE_BODY_TYPE_CONVERSATION',
  MESSAGE_BODY_TYPE_EVENT: 'MESSAGE_BODY_TYPE_EVENT',
  // local type for separator date
  MESSAGE_BODY_TYPE_SEPARATOR_DATE: 'MESSAGE_BODY_TYPE_SEPARATOR_DATE',
};

export const GROUP_CHAT_TYPE = {
  // acces_type when user create group
  GROUP_TYPE_UNSPECIFIED: 'GROUP_TYPE_UNSPECIFIED',
};

export const CURSOR_DIR = {
  CURSOR_DIRECTION_UNSPECIFIED: 'CURSOR_DIRECTION_UNSPECIFIED',
  CURSOR_DIRECTION_IN_FRONT: 'CURSOR_DIRECTION_IN_FRONT',
  CURSOR_DIRECTION_BEHIND: 'CURSOR_DIRECTION_BEHIND',
  CURSOR_DIRECTION_BETWEEN: 'CURSOR_DIRECTION_BETWEEN',
};

export const CHAT_PUSH_NOTIFICATION_INDEX = 1;

export const MAX_TOTAL_UNREAD = 50;

export const MESSAGE_WS_BODY_TYPE = {
  MESSAGE_BODY_TYPE_UNSPECIFIED: 'MESSAGE_BODY_TYPE_UNSPECIFIED',
  MESSAGE_BODY_TYPE_CONVERSATION: 'MESSAGE_BODY_TYPE_CONVERSATION',
  MESSAGE_BODY_TYPE_EVENT: 'MESSAGE_BODY_TYPE_EVENT',
};

export const DISPLAYED_AS = {
  DISPLAYED_AS_SENDER: 'DISPLAYED_AS_SENDER',
  DISPLAYED_AS_RECEIVER: 'DISPLAYED_AS_RECEIVER',
};

export const ALLOWED_UPLOADED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/webp',
];

export const ALLOWED_UPLOADED_DOCUMENT_TYPES = ['application/pdf'];
