import Axios, { AxiosError, AxiosResponse } from 'axios';
import alertMessage from 'global/AlertMessage';
import tradingStore from 'lib/stores/trading';
import ERROR_MESSAGE from 'constants/errorMessage';
import securitiesLocalStorage from 'utils/securitiesLocalStorage';
import { postAuthRefresh } from 'global/Securities/api/auth';
import {
  BondBlockerType,
  bondsErrorTypesMapping,
} from 'global/TradingModal/RealTradingModal/ModalBonds/constants';
import useBondError from 'global/TradingModal/RealTradingModal/ModalBonds/stores/useBondError';
import { getErrorConfig /* logoutSecurities */ } from './utils';

// #region error type handler

export const dispatchSetErrorCode = ({
  status,
  message,
  type,
}: Partial<TradingErrorConfig>) => {
  tradingStore.getState().setErrorCode(status, message, type);
};

export const handleSetErrorCode = (error: AxiosError): Promise<AxiosError> => {
  const errorConfig = getErrorConfig(error);
  dispatchSetErrorCode(errorConfig);
  return Promise.reject<AxiosError>(error);
};

export const handleNoResponse = (error: AxiosError): Promise<AxiosError> => {
  dispatchSetErrorCode({ status: 500 });
  return Promise.reject<AxiosError>(error);
};

export const handleShowAlertMessage = (
  error: AxiosError,
  message?: string,
): Promise<AxiosError> => {
  const errorConfig = getErrorConfig(error);

  if (message || errorConfig.message) {
    alertMessage({
      content: message || errorConfig.message,
      alertType: 'plain-default',
      messageType: 'error',
      hasCloseIcon: false,
    });
  }

  dispatchSetErrorCode(errorConfig);
  return Promise.reject(error);
};

export const handleBondsError = (error: AxiosError): Promise<AxiosError> => {
  const errorConfig = getErrorConfig(error);

  const bondBlockerType =
    bondsErrorTypesMapping[errorConfig.type] || BondBlockerType.UNSPECIFIED;

  if (bondBlockerType === BondBlockerType.UNSPECIFIED && errorConfig.message) {
    alertMessage({
      content: errorConfig.message,
      alertType: 'plain-default',
      messageType: 'error',
      hasCloseIcon: false,
    });
    return Promise.reject(error);
  }

  useBondError
    .getState()
    .setErrorCode(400, errorConfig.message, errorConfig.type);
  return Promise.reject(error);
};

export const badRequestHandlerList: TradingErrorHandlerList = {
  // Bonds Error handlers
  product_not_found: handleBondsError,
  price_rate_not_found: handleBondsError,
  fr_in_record_date_period: handleBondsError,
  fr_bonds_market_close: handleBondsError,
  stockbit_get_account_not_found: handleBondsError,
  trading_account_suspended: handleBondsError,
  price_has_changed: handleBondsError,
  fr_existing_order_inprogress: handleBondsError,
  portfolio_not_exist: handleBondsError,
  redeem_amount_greater_than_redeemable_amount: handleBondsError,
  not_in_secondary_market_period: handleBondsError,
  sell_order_inprogress: handleBondsError,
  user_sharia_validation: handleBondsError,
  stable_earn_cannot_be_sold_outside_bibit: handleBondsError,
};

export const unauthorizedHandlerList: TradingErrorHandlerList = {
  INVALID_AUTH: handleSetErrorCode,
  INVALID_TOKEN: handleSetErrorCode,
  INVALID_SESSION: handleSetErrorCode,
  MOCK_TRADING: handleSetErrorCode,
  MAINTENANCE_MODE: handleSetErrorCode,
  DAILY_MAINTENANCE_MODE: handleSetErrorCode,
  INVALID_CREDS: handleSetErrorCode,
  UNAUTHORIZED: handleSetErrorCode,
};

export const forbiddenErrorList: TradingErrorHandlerList = {
  USER_SUSPEND: handleSetErrorCode,
  FORBIDDEN: handleSetErrorCode,
};

export const serviceUnavailableHandlerList: TradingErrorHandlerList = {
  DAILY_MAINTENANCE_MODE: handleSetErrorCode,
  MAINTENANCE_MODE: handleSetErrorCode,
};

export const internalServerErrorHandlerList: TradingErrorHandlerList = {
  SYSTEM_ERROR: handleSetErrorCode,
};

// #endregion error type handler

// #region Status Handler

export const handleRefreshToken = async (
  error: AxiosError,
): Promise<AxiosError | AxiosResponse> => {
  try {
    const { securitiesRefreshToken: currentRefreshToken } =
      securitiesLocalStorage.getSecuritiesToken();

    if (!currentRefreshToken) {
      throw new Error('Securities refresh token is not exists');
    }

    const { access_token: accessToken, refresh_token: refreshToken } =
      await postAuthRefresh();

    securitiesLocalStorage.saveSecuritiesToken({
      securitiesAccessToken: accessToken,
      securitiesRefreshToken: refreshToken,
    });

    return Axios.request({
      ...error.config,
      headers: {
        ...error.config.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    }).catch((refreshError) => {
      handleSetErrorCode(refreshError);
      return Promise.reject(refreshError);
    });
  } catch (e) {
    handleSetErrorCode(error);
    return Promise.reject(error);
  }
};

export const handleBadRequest = (
  error: AxiosError,
): Promise<AxiosError | AxiosResponse> => {
  const { type } = getErrorConfig(error);
  const errorHandler = badRequestHandlerList[type];

  if (errorHandler) return errorHandler(error);

  handleShowAlertMessage(error);
  return Promise.reject(error);
};

export const handleUnauthorized = (
  error: AxiosError,
): Promise<AxiosError | AxiosResponse> => {
  if (!error.response.data) return handleRefreshToken(error);

  const { type } = getErrorConfig(error);
  const errorHandler = unauthorizedHandlerList[type];

  if (errorHandler) return errorHandler(error);

  handleShowAlertMessage(error);
  return Promise.reject(error);
};

export const handleForbidden = (
  error: AxiosError,
): Promise<AxiosError | AxiosResponse> => {
  if (!error.response.data) return handleRefreshToken(error);

  const { type } = getErrorConfig(error);
  const errorHandler = forbiddenErrorList[type];

  if (errorHandler) return errorHandler(error);

  return Promise.reject(error);
};

export const handleServiceUnavailable = (
  error: AxiosError,
): Promise<AxiosError> => {
  const { type } = getErrorConfig(error);
  const errorHandler = serviceUnavailableHandlerList[type];
  if (errorHandler) return errorHandler(error);

  handleShowAlertMessage(error, ERROR_MESSAGE.UNKNOWN_SYSTEM_ERROR);
  return Promise.reject(error);
};

export const handleInternalServerError = (
  error: AxiosError,
): Promise<AxiosError> => {
  const { type } = getErrorConfig(error);

  const errorHandler = internalServerErrorHandlerList[type];
  if (errorHandler) return errorHandler(error);

  handleShowAlertMessage(error, ERROR_MESSAGE.UNKNOWN_SYSTEM_ERROR);
  return Promise.reject(error);
};

// Refer here for function naming
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
export const tradingErrorStatusHandlerList: TradingErrorStatusHandlerList = {
  400: handleBadRequest,
  401: handleUnauthorized,
  403: handleForbidden,
  503: handleServiceUnavailable,
  500: handleInternalServerError,
};

// #endregion Status Handler
