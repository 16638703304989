import requestConfig from 'config/requestExodus';
import { API_EXODUS_URL } from 'constants/api';
import { APIPromiseResponse } from '../../../@types/api-response';

const request = requestConfig();

const SEARCH_URL = `${API_EXODUS_URL}/search`;
const SEARCH_RECENT = `${SEARCH_URL}/recent`;

const SEARCH_TYPES = {
  USERS: 'users',
  ALL: 'all',
  COMPANY: 'company',
  EMITEN: 'emiten',
};

const getSearchPeople = (keyword: string, page = 0): APIPromiseResponse =>
  request.get(SEARCH_URL, {
    params: {
      keyword,
      page,
      type: SEARCH_TYPES.USERS,
    },
  });

const getSearchCompany = (keyword: string, page = 0): APIPromiseResponse => {
  if (keyword.length > 1) {
    return request.get(SEARCH_URL, {
      params: {
        keyword,
        page,
        type: SEARCH_TYPES.COMPANY,
      },
    });
  }

  return null;
};

const getSearchRecent = (): APIPromiseResponse => request.get(SEARCH_RECENT);

const postSearchRecent = (
  keyword: string,
  target: number,
  type: string,
): APIPromiseResponse => request.post(SEARCH_RECENT, { keyword, target, type });

const getSearch = (keyword: string): APIPromiseResponse => {
  if (keyword.length > 1) {
    return request.get(SEARCH_URL, {
      params: {
        keyword,
        page: 0,
        type: SEARCH_TYPES.ALL,
      },
    });
  }

  return null;
};

const getSearchEmiten = (keyword: string, page = 0): APIPromiseResponse =>
  request.get(SEARCH_URL, {
    params: {
      keyword,
      page,
      type: SEARCH_TYPES.EMITEN,
      country: 'ID',
    },
  });

// Exports ---------------------------------------------------------------------
export default {
  getSearchPeople,
  getSearchCompany,
  getSearchRecent,
  getSearch,
  postSearchRecent,
  getSearchEmiten,
};
