import { SmartOrderCode, TransactionType, Expiry } from '../types/transaction';
import { OrderTypeOptions } from '../types/transactionForm';

export const balanceLabel = {
  cashOnHand: 'Cash On Hand',
  tradingBalance: 'Trading Balance',
  tradingLimit: 'Trading Limit',
  dayTrade: 'Buying Power',
};

export const smartOrderText: Record<SmartOrderCode, string> = {
  [SmartOrderCode.AutoBuy]: 'Auto Buy',
  [SmartOrderCode.StopLoss]: 'Stop Loss',
  [SmartOrderCode.TakeProfit]: 'Take Profit',
};

export const orderTypeOptions: OrderTypeOptions = {
  [TransactionType.Buy]: {
    'Limit Order': 'Limit Order',
    AutoBuy: smartOrderText.AB,
    'Auto Buy': smartOrderText.AB,
    Market: 'Market Order',
  },
  [TransactionType.Sell]: {
    'Limit Order': 'Limit Order',
    StopLoss: smartOrderText.SL,
    TakeProfit: smartOrderText.TP,
    Market: 'Market Order',
  },
};

export const expiryOptions: Record<Expiry, string> = {
  [Expiry.GoodForDay]: 'Good For Day',
  [Expiry.GoodTillCancelled]: 'Good Till Cancelled',
};

export const expiryId: Record<Expiry, number> = {
  [Expiry.GoodForDay]: 0,
  [Expiry.GoodTillCancelled]: 1,
};

export const expiryTypeById: Record<number, Expiry> = {
  0: Expiry.GoodForDay,
  1: Expiry.GoodTillCancelled,
};
