import { string, number, object } from 'prop-types';

import intercom from '../user/intercom';

import accessUser from './accessUser';

const schema = {
  access_user: accessUser.schema,
  access_token: '',
  access_token_exp: 0,
  refresh_token: '',
  refresh_token_exp: 0,
  trading: 0,
  intercom: intercom.schema,
  onesignal_hash: '',
  support: {
    id: '',
  },
};

const propTypes = {
  access_user: object,
  access_token: string,
  access_token_exp: number,
  refresh_token: string,
  refresh_token_exp: number,
  trading: string,
  intercom: intercom.schema,
  onesignal_hash: string,
  support: {
    id: string,
  },
};

export default { schema, propTypes };
