import moment from 'moment';

import { SendMessage } from '@stockbitgroup/protos/social/wssocial/entity/message/v1/send_pb';

import { socketV2Key, WEBSOCKET_READYSTATE } from 'features/chat/constant';
import { getSocketV2 } from '.';
import { PROJECT_FOLDER_PATH } from 'constants/app';

interface IWsLogger {
  module: string;
  action: string;
  logLevel?: string;
  payload?: any;
}

/**
 * isConnectionOpen
 * @param {Number} readyState
 * @returns {Boolean}
 */
export const isConnectionOpen = (readyState) =>
  readyState === WEBSOCKET_READYSTATE.OPEN;

/**
 * sendMessageWsSocial
 * @param {object} payload
 * @param {object} webSocketSocialV2
 * @returns
 */
export const sendMessageWsSocial = (payload) => {
  const webSocketSocialV2 = getSocketV2(socketV2Key.social);
  if (!webSocketSocialV2) {
    return;
  }

  const sendDataBinary = SendMessage.fromJson(payload).toBinary();
  // send message
  if (isConnectionOpen(webSocketSocialV2.readyState)) {
    webSocketSocialV2.send(sendDataBinary);
  }
};

export const wsLogger = ({
  module = '',
  action = '',
  logLevel = 'log',
  payload = undefined,
}: IWsLogger): void => {
  // Only allow on ui.lab
  if (!PROJECT_FOLDER_PATH) {
    return;
  }
  const date = moment(new Date()).format('YYYY-MM-DD');
  const time = moment(new Date(), 'HH:mm').format('HH:mm');
  console.group(`[${date}-${time}][WS Debug][${module}]: ${action}`);
  if (payload) console[logLevel](payload);
  console.groupEnd();
};
