import { capitalizeFirstLetter } from 'utils/general';
import { ChatRoomType, ChatRoomTypeShort } from '../types';

export function roomTypeToRoomTypeParams(roomType: ChatRoomType) {
  const types: Record<ChatRoomType, ChatRoomTypeShort> = {
    ROOM_TYPE_BROADCAST: 'broadcast',
    ROOM_TYPE_GROUP: 'group',
    ROOM_TYPE_PERSONAL: 'personal',
  };
  const type = types[roomType];
  if (!type) {
    return undefined;
  }
  // capitalize
  return capitalizeFirstLetter(type);
}
