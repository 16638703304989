import { DATA_WS_LOGGER, WEBSOCKET_READYSTATE } from 'features/chat/constant';
import { wsLogger } from './utils';

const DATAFEED_SOCKET = process.env.NEXT_PUBLIC_DATAFEED_SOCKET;
const SOCIAL_SOCKET = process.env.NEXT_PUBLIC_SOCIAL_SOCKET;

const socketV2Manager = {
  social: null,
};

/**
 * Store websocket instance to socket manager, also change binaryType to arraybuffer
 * @param {string} key Key of websocket in socket manager
 * @param {WebSocket} socket Instance of the websocket
 * @returns {WebSocket} WebSocket instance
 */
export const setSocketV2 = (key, socket) => {
  socketV2Manager[key] = socket;
  socketV2Manager[key].binaryType = 'arraybuffer';
  return socketV2Manager[key];
};

/**
 * Get websocket instance from socket manager, will return undefined if not available
 * @param {string} key Key of websocket in socket manager
 * @returns {WebSocket | undefined} WebSocket instance
 */
export const getSocketV2 = (key) => socketV2Manager[key];

const initWebsocketV2 = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  // @ts-ignore
  if (window.websocketV2) {
    // @ts-ignore
    window.websocketV2.dispatchEvent(new Event('socketv2_reconnected'));
    // @ts-ignore
    return window.websocketV2;
  }

  const websocketV2 = new WebSocket(DATAFEED_SOCKET);
  websocketV2.binaryType = 'arraybuffer';

  // @ts-ignore
  window.websocketV2 = websocketV2;
  return websocketV2;
};

const composeWSSocialUrl = (wskey) => {
  const url = new URL(SOCIAL_SOCKET);
  const search = new URLSearchParams({ wskey });

  url.search = decodeURIComponent(search.toString());

  return url.toString();
};

const initWebsocketSocialV2 = (socketKey, wsKey) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const wsUrl = composeWSSocialUrl(wsKey);

  // close previous websocketSocialV2 connection
  // should ready to close prev ws connection
  if (
    window.websocketSocialV2 &&
      window.websocketSocialV2.readyState === WEBSOCKET_READYSTATE.OPEN
  ) {
    wsLogger({
      module: DATA_WS_LOGGER.MODULE_NAME,
      action: DATA_WS_LOGGER.ACTION.CLOSE_PREVIOUS_CONNECTION,
      logLevel: DATA_WS_LOGGER.LOG_LEVEL.WARN,
    });
    window.websocketSocialV2.close();
  }

  wsLogger({
    module: DATA_WS_LOGGER.MODULE_NAME,
    action: DATA_WS_LOGGER.ACTION.INITIALIZE_NEW_CONNECTION,
    payload: wsKey,
  });

  const websocketSocialV2 = setSocketV2(socketKey, new WebSocket(wsUrl));

  // @ts-ignore
  window.websocketSocialV2 = websocketSocialV2;
  return websocketSocialV2;
};

export { initWebsocketV2, initWebsocketSocialV2 };
