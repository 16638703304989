import { isClient } from 'constants/app';
import {
  WEBSOCKET_WS_SOCIAL_PING_INTERNAL,
  WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION,
  DEBUG_WS_SOCIAL,
} from 'features/chat/constant';

const saveIdIntervalPingWsSocial = (idInterval) => {
  if (isClient()) {
    localStorage.setItem(WEBSOCKET_WS_SOCIAL_PING_INTERNAL, idInterval);
  }
};

const getIdIntervalPingWsSocial = () => {
  return localStorage.getItem(WEBSOCKET_WS_SOCIAL_PING_INTERNAL);
};

const removeIdIntervalPingWsSocial = () => {
  localStorage.removeItem(WEBSOCKET_WS_SOCIAL_PING_INTERNAL);
};

const storeErrorNoReconnectionWsSocial = (value) => {
  localStorage.setItem(WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION.NAME, value);
};

const getErrorNoReconnectionWsSocial = () => {
  return localStorage.getItem(WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION.NAME);
};

const getFeatureFlagDebugWsSocial = () => {
  return localStorage.getItem(DEBUG_WS_SOCIAL) || 'false';
};

const storeFeatureFlagDebugWsSocial = (isAllowed: string) => {
  localStorage.setItem(DEBUG_WS_SOCIAL, isAllowed);
};

const removeFeatureFlagDebugWsSocial = () => {
  localStorage.removeItem(DEBUG_WS_SOCIAL);
};

export {
  saveIdIntervalPingWsSocial,
  getIdIntervalPingWsSocial,
  removeIdIntervalPingWsSocial,
  getErrorNoReconnectionWsSocial,
  storeErrorNoReconnectionWsSocial,
  getFeatureFlagDebugWsSocial,
  storeFeatureFlagDebugWsSocial,
  removeFeatureFlagDebugWsSocial,
};
