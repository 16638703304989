import { clevertapEvent } from 'utils/clevertap';
import { ChatRoomType, ChatRoomTypeShort } from '../types';
import { roomTypeToRoomTypeParams } from './utils';

export const TAGS = {
  symbol: 'Symbol',
  account: 'Account',
  symbolAndAccount: 'Symbol & Account',
  none: 'None',
};

export const FORWARD_RECEIVER = {
  single: 'Single',
  multiple: 'Multiple',
  none: 'None',
};

export const ATTACHMENT_TYPE = {
  image: 'Image',
  none: 'None',
  chooseExistingPicture: 'Choose Existing Picture',
  documents: 'Documents',
  gif: 'GIF',
};

const EVENTS = {
  chatListViewed: 'Chat List Viewed',
  startChattingSelected: 'Start Chatting Selected',
  chatSearchUserFilled: 'Chat Search User Filled',
  newChatStarted: 'New Chat Started',
  chatRoomViewed: 'Chat Room Viewed',
  chatAddAttachment: 'Chat Add Attachment',
  chatAttachmentSelected: 'Chat Attachment Selected',
  chatSend: 'Chat Send',
  chatTagSelected: 'Chat Tag Selected',
  chatAddTag: 'Chat Add Tag',
  chatRoomMoreSelected: 'Chat Room Menu Selected',
  chatRoomCleared: 'Chat Room Cleared',
  chatRoomDeleted: 'Chat Room Deleted',
  chatBlockUser: 'Chat Block User',
  chatBlockUserConfirmed: 'Chat Block User Confirmed',
  chatUnblockUser: 'Chat Unblock User',
  chatUnblockUserConfirmed: 'Chat Unblock User Confirmed',
};

const GeneralEventAnalytics = {
  onChatListViewed: (isEmpty = false) => {
    clevertapEvent(EVENTS.chatListViewed, {
      'Is Empty': isEmpty,
    });
  },
  onStartChatting: (isEmpty = false) => {
    clevertapEvent(EVENTS.startChattingSelected, {
      'Is Empty': isEmpty,
    });
  },
  onChatSearchUserFilled: () => {
    clevertapEvent(EVENTS.chatSearchUserFilled);
  },
  onNewChatStarted: () => {
    clevertapEvent(EVENTS.newChatStarted);
  },
  onChatroomViewed: (
    source, // see CHAT_SOURCES
    isMessageRequest = false,
    roomId,
    type: ChatRoomTypeShort = 'personal',
  ) => {
    clevertapEvent(EVENTS.chatRoomViewed, {
      Source: source,
      'Is Message Request': isMessageRequest,
      'Message ID': roomId,
      'Chat Room Type': type.charAt(0).toUpperCase() + type.slice(1),
    });
  },
  onChatAddAttachment: () => {
    clevertapEvent(EVENTS.chatAddAttachment);
  },
  onChatAddAttachmentSelected: (attachment) => {
    clevertapEvent(EVENTS.chatAttachmentSelected, {
      Attachment: attachment, // see Attachment
    });
  },
  onChatSend: (
    attachment, // see ATTACHMENT
    tags, // see TAGS
    isForwarded = false,
    isReplied = false,
    forwardReceiver, // see FORWARD_RECEIVER,
    messageId = 0,
    type: ChatRoomType = 'ROOM_TYPE_PERSONAL',
  ) => {
    clevertapEvent(EVENTS.chatSend, {
      Attachment: attachment,
      Tags: tags,
      'Is Forwarded': isForwarded,
      'Is Replied': isReplied,
      'Forward Receiver': forwardReceiver,
      'Message ID': messageId,
      'Chat Room Type': roomTypeToRoomTypeParams(type),
    });
  },
  onChatTagSelected: (
    symbol = '',
    isPersonalChat = true,
    messageId = 0,
    chatRoomType: ChatRoomType = 'ROOM_TYPE_PERSONAL',
  ) => {
    clevertapEvent(EVENTS.chatTagSelected, {
      Emiten: symbol.toUpperCase(),
      'Message ID': messageId,
      'Chat Room Type': roomTypeToRoomTypeParams(chatRoomType),
    });
  },
  onChatAddTagClicked: (symbol = '', isPersonalChat = true) => {
    clevertapEvent(EVENTS.chatAddTag, {
      Emiten: symbol.toUpperCase(),
      'Chat Room Type': isPersonalChat ? 'Personal' : 'Group',
    });
  },
  onChatRoomMoreSelected: (
    roomId: number,
    type: ChatRoomType = 'ROOM_TYPE_PERSONAL',
  ) => {
    clevertapEvent(EVENTS.chatRoomMoreSelected, {
      'Room ID': roomId,
      'Chat Room Type': roomTypeToRoomTypeParams(type),
    });
  },
  onChatRoomCleared: () => {
    clevertapEvent(EVENTS.chatRoomCleared);
  },
  onChatRoomDeleted: (
    isMessageRequest = false,
    roomId: number,
    type: ChatRoomType = 'ROOM_TYPE_PERSONAL',
  ) => {
    clevertapEvent(EVENTS.chatRoomDeleted, {
      'Is Message Request': isMessageRequest,
      'Room ID': roomId,
      'Chat Room Type': roomTypeToRoomTypeParams(type),
    });
  },
  onBlockUser: () => {
    clevertapEvent(EVENTS.chatBlockUser);
  },
  onBlockUserConfirmed: () => {
    clevertapEvent(EVENTS.chatBlockUserConfirmed);
  },
  onUnBlockUser: () => {
    clevertapEvent(EVENTS.chatUnblockUser);
  },
  onUnBlockUserConfirmed: () => {
    clevertapEvent(EVENTS.chatUnblockUserConfirmed);
  },
};

export default GeneralEventAnalytics;
