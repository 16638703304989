export const BOARD_TYPE = {
  saham: 'RG',
  right: 'TN',
};

export const STOCK_TYPE = {
  saham: 'saham',
  right: 'right',
  waran: 'waran',
  index: 'index',
  index_asing: 'index_asing',
  reksadana: 'reksadana',
  commodities: 'commodities',
  crypto: 'crypto',
  fx: 'fx',
  etf: 'etf',
  bond: 'bond',
} as const;

export const ORDER_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
  AMEND_BUY: 'AMEND_BUY',
  AMEND_SELL: 'AMEND_SELL',
  EXERCISE: 'EXERCISE',
  VIRTUAL_BUY: 'VIRTUAL_BUY',
  VIRTUAL_SELL: 'VIRTUAL_SELL',
  VIRTUAL_AMEND_BUY: 'VIRTUAL_AMEND_BUY',
  VIRTUAL_AMEND_SELL: 'VIRTUAL_AMEND_SELL',
  BOND: 'BOND_TRANSACTION',
};

export const ORDER_EXPIRY_TEXT = {
  gfd: 'Good For Day',
  gtc: 'Good Till Cancelled',
  gtcSixMonth: 'Good Till Cancelled (6 Months)',
};

export const BUY_ORDER_TYPE_TEXT = {
  LO: 'Limit Order',
  AB: 'Auto Buy',
};

export const CORPORATE_ACTION_STOCK_TYPE = ['WARAN', 'RIGHT', 'ETF'];

export const DEPOSIT_OTHER_BANK_CODE = '999';

export const ORDER_STATUS_COLOR = {
  order: {
    open: '#00ab6b',
    active: '#00ab6b',
    pending: '#00ab6b',
    ready: '#00ab6b',
    partial: '#00ab6b',
    'request amend': '#333333',
    amended: '#333333',
    'request cancel': '#333333',
    withdrawn: '#333333',
    match: '#333333',
    rejected: '#EE4A49',
  },
  exercise: {
    success: '#333333',
    processed: '#00ab6b',
    pending: '#00ab6b',
    rejected: '#EE4A49',
  },
};

export const enum UserTargetAPI {
  LEGACY = '1',
  NEW_CORE = '2',
}

export const LOT_ORDER_LIMIT: Record<UserTargetAPI, number> = {
  [UserTargetAPI.LEGACY]: 999999,
  [UserTargetAPI.NEW_CORE]: 9999999,
};

export const SPLIT_ORDER_LOT = 50000;

export const CorporateActionStockType = [
  STOCK_TYPE.waran,
  STOCK_TYPE.right,
  STOCK_TYPE.etf,
];
