/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable camelcase */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import handleErrorMessageAPI from 'global/AlertErrorMessage';
import SUCCESS_MESSAGE from 'constants/successMessage';
import { effects as userEffects } from 'lib/entities/user';
import chatApi from 'lib/api/chat';
import alertMessage from 'global/AlertMessage';
import { CommonPayload } from '../../../../@types/action-payload';
import {
  GetRoomParams,
  RoomIDParam,
  SendChatParams,
  RoomListWithIdPayload,
  SendBulkChatParams,
  IncomingChatParams,
  GetPolicyParams,
  ReplyToParams,
  SelectParams,
  ForwardChatParams,
  MuteNotificationParams,
  getSearchReceiversParams,
  RespondInvitationParams,
  SetActiveRoomId,
} from './slice.types';
import { RoomItemPayload } from '../../../../@types/chat';

import { DEFAULT_CHAT_ROOM_ID } from 'features/chat/constant';

import { uniq } from '../../../utils/chat';

/**
 * Note on conversation schema:
 * Conversation schema will be filled with actual response from API arranged by its chat id
 * thus it will looks like:
 * conversation: {
 *  [chatId]: {
 *    data: Array<Record<string, any>>,
 *    more: boolean,
 *  }
 * }
 */
export const initialState = {
  activeRoomId: DEFAULT_CHAT_ROOM_ID,
  conversation: {},
  lastMessage: {},
  error: null,
  isLoading: false,
  isLoadingRooms: false,
  isLoadMessage: false,
  isLoadMoreRooms: false,
  isLoadPrevMessage: false,
  isLoadAcceptRequest: false,
  isLoadRejectRequest: false,
  message: '',
  rooms: [],
  room: {
    group: {},
    personal: {},
  },
  requests: [],
  requestCount: 0,
  unreadRequest: 0,
  reply: {
    visible: false,
    to_id: 0,
  },
  select: {
    visible: false,
    messageIds: [],
    selectedAlign: [],
  },
  receivers: {
    list: [],
  },
  invitation: {
    isLoadingAccept: false,
    isLoadingReject: false,
  },
};

type ChatState = typeof initialState;

// Selectors
const chatSelector = (state) => state.entities.chat;
export const selectors = createSelector(chatSelector, (chat: ChatState) => ({
  ...chat,
}));

// Actions
const CONTEXT = '@redux/chat';

const actionType = {
  SET_ACTIVE_ROOM_ID: `${CONTEXT}/SET_ACTIVE_ROOM_ID`,
  RESET_ACTIVE_ROOM_ID: `${CONTEXT}/RESET_ACTIVE_ROOM_ID`,
  GET_ROOMS: `${CONTEXT}/GET_ROOMS`,
  SEND_CHAT: `${CONTEXT}/SEND_CHAT`,
  SEND_BULK_CHAT: `${CONTEXT}/SEND_BULK_CHAT`,
  DELETE_CHAT_ROOM: `${CONTEXT}/DELETE_CHAT_ROOM`,
  READ_CHAT: `${CONTEXT}/READ_CHAT`,
  GET_REQUESTED_ROOMS: `${CONTEXT}/GET_REQUESTED_ROOMS`,
  ACCEPT_REQUESTED_ROOM: `${CONTEXT}/ACCEPT_REQUESTED_ROOM`,
  REJECT_REQUESTED_ROOM: `${CONTEXT}/REJECT_REQUESTED_ROOM`,
  GET_POLICY: `${CONTEXT}/GET_POLICY`,
  FORWARD_CHAT: `${CONTEXT}/FORWARD_MESSAGE`,
  SHARE_EVENT_TO_CHAT: `${CONTEXT}/SHARE_TO_CHAT`,
  GET_SEARCH_RECEIVERS: `${CONTEXT}/GET_SEARCH_RECEIVERS`,
  MUTE_GROUP_NOTIFICATION: `${CONTEXT}/MUTE_GROUP_NOTIFICATION`,
  RESPOND_GROUP_INVITATION: `${CONTEXT}/RESPOND_GROUP_INVITATION`,
};

// Side effects
export const effects = {
  setActiveRoomId: createAsyncThunk<CommonPayload, SetActiveRoomId>(
    actionType.SET_ACTIVE_ROOM_ID,
    (params) => {
      try {
        return { data: params };
      } catch (error) {
        return { error };
      }
    },
  ),
  resetActiveRoomId: createAsyncThunk<CommonPayload>(
    actionType.RESET_ACTIVE_ROOM_ID,
    () => {
      try {
        return { data: initialState.activeRoomId };
      } catch (error) {
        return { error };
      }
    },
  ),
  getRooms: createAsyncThunk<CommonPayload, GetRoomParams>(
    actionType.GET_ROOMS,
    async (param) => {
      try {
        const response = await chatApi.getRooms(param);

        if (!response.data) {
          throw new Error('Attempt to get room list');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, offset: param.offset };
      } catch (error) {
        return { error };
      }
    },
  ),
  sendChat: createAsyncThunk<CommonPayload, SendChatParams>(
    actionType.SEND_CHAT,
    async (params) => {
      try {
        const response = await chatApi.sendChat(params);

        if (!response.data) {
          throw new Error('Attempt to send chat failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  sendBulkChat: createAsyncThunk<CommonPayload, SendBulkChatParams>(
    actionType.SEND_BULK_CHAT,
    async (params) => {
      try {
        const response = await chatApi.sendBulkChat(params);

        if (!response.data) {
          throw new Error('Attempt to send chat failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { message, error };
        }

        handleErrorMessageAPI(
          SUCCESS_MESSAGE.POST_SHARED_SUCCESSFUL,
          SUCCESS_MESSAGE.SNACKBAR_GREEN,
          true,
        );

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  deleteChatRoom: createAsyncThunk<CommonPayload, RoomIDParam>(
    actionType.DELETE_CHAT_ROOM,
    async (roomId) => {
      try {
        const response = await chatApi.deleteChatRoom(roomId);

        if (!response.data) {
          throw new Error('Attempt to delete chat failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, roomId };
      } catch (error) {
        return { error };
      }
    },
  ),
  readChat: createAsyncThunk<RoomListWithIdPayload, RoomIDParam>(
    actionType.READ_CHAT,
    async (roomId) => {
      try {
        const response = await chatApi.readChat(roomId);

        if (!response.data) {
          throw new Error('Attempt to read chat failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, roomId };
      } catch (error) {
        return { error };
      }
    },
  ),
  getRequestedRooms: createAsyncThunk<CommonPayload, GetRoomParams>(
    actionType.GET_REQUESTED_ROOMS,
    async (param) => {
      try {
        const response = await chatApi.getRequestedRooms(param);

        if (!response.data) {
          throw new Error('Attempt to get room list');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, offset: param.offset };
      } catch (error) {
        return { error };
      }
    },
  ),
  acceptRequestedRoom: createAsyncThunk<CommonPayload, RoomIDParam>(
    actionType.ACCEPT_REQUESTED_ROOM,
    async (roomId) => {
      try {
        const response = await chatApi.acceptRequest(roomId);

        if (!response.data) {
          throw new Error('Attempt to accept room');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, roomId };
      } catch (error) {
        return { error };
      }
    },
  ),
  rejectRequestedRoom: createAsyncThunk<CommonPayload, RoomIDParam>(
    actionType.REJECT_REQUESTED_ROOM,
    async (roomId) => {
      try {
        const response = await chatApi.rejectRequest(roomId);

        if (!response.data) {
          throw new Error('Attempt to reject room');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, roomId };
      } catch (error) {
        return { error };
      }
    },
  ),
  getPolicy: createAsyncThunk<CommonPayload, GetPolicyParams>(
    actionType.GET_POLICY,
    async (params) => {
      try {
        const response = await chatApi.getPolicy(params);

        if (!response.data) {
          throw new Error('Attempt to get policy');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data };
      } catch (error) {
        return { error };
      }
    },
  ),
  forwardChat: createAsyncThunk<CommonPayload, ForwardChatParams>(
    actionType.FORWARD_CHAT,
    async (params) => {
      try {
        const response = await chatApi.forwardChat(params);

        if (!response.data) {
          throw new Error('Attempt to send chat failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { message, error };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  shareEventToChat: createAsyncThunk<CommonPayload, SendBulkChatParams>(
    actionType.SHARE_EVENT_TO_CHAT,
    async (params) => {
      try {
        const response = await chatApi.sendBulkChat(params);

        if (!response.data) {
          throw new Error('Attempt to send chat failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { message, error };
        }

        handleErrorMessageAPI(
          SUCCESS_MESSAGE.EVENT_SHARED_SUCCESSFUL,
          SUCCESS_MESSAGE.SNACKBAR_GREEN,
          true,
        );

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  muteNotification: createAsyncThunk<CommonPayload, MuteNotificationParams>(
    actionType.MUTE_GROUP_NOTIFICATION,
    async ({ roomId, isMuted }) => {
      try {
        const response = await chatApi.muteNotification(roomId, isMuted);

        if (!response.data) {
          throw new Error('Attempt to mute notification failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, roomId };
      } catch (error) {
        return { error };
      }
    },
  ),
  getSearchReceivers: createAsyncThunk<CommonPayload, getSearchReceiversParams>(
    actionType.GET_SEARCH_RECEIVERS,
    async (param) => {
      try {
        const response = await chatApi.getSearchReceivers(param.keyword);

        if (!response.data) {
          throw new Error('Attempt to get search receivers');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
  respondGroupInvitation: createAsyncThunk<
    CommonPayload,
    RespondInvitationParams
  >(actionType.RESPOND_GROUP_INVITATION, async ({ roomId, action }) => {
    try {
      const response = await chatApi.respondInvitation(roomId, action);

      if (!response.data) {
        throw new Error('Attempt to accept room');
      }

      const { error, message, data } = response.data;

      if (error) {
        return { error, message };
      }

      return { data, message, roomId };
    } catch (error) {
      return { error };
    }
  }),
};

// Reducers
const reducers = {
  // update current active chat
  updateChat: (state: ChatState, action: PayloadAction<IncomingChatParams>) => {
    const { chatId, chat } = action.payload;
    if (state.conversation[chatId]) {
      const dataConvo = state.conversation[chatId];
      const isOnConvo = dataConvo.data.some((message) => message.id === chatId);

      if (!isOnConvo) {
        state.conversation[chatId]?.data?.unshift(chat);
      }
    } else {
      state.conversation[chatId] = {
        data: [chat],
      };
    }
  },
  // Update Room List
  updateRoomList: (
    state: ChatState,
    action: PayloadAction<RoomItemPayload>,
  ) => {
    const newChat = action.payload;
    const isPrivateChat = newChat.state === 'ROOM_STATE_ACCEPTED';
    // decide where to place updated chat/room
    // rooms is for private, and requests is for request chat
    const stateBucket = isPrivateChat ? 'rooms' : 'requests';
    const newChatList = state[stateBucket];

    const roomIdx = newChatList.findIndex((room) => room.id === newChat.id);

    const isRoomAvail = roomIdx > -1;
    if (isRoomAvail) {
      newChatList.splice(roomIdx, 1);
    }

    newChatList.unshift(newChat);
    state[stateBucket] = newChatList;

    if (!isPrivateChat && !isRoomAvail) {
      state.requestCount += 1;
    }

    if (!isPrivateChat) {
      if (isRoomAvail && state.requests[roomIdx].unread_count === 0) {
        state.unreadRequest += 1;
      } else if (!isRoomAvail) {
        state.unreadRequest += 1;
      }
    }
  },

  replyTo: (state: ChatState, action: PayloadAction<ReplyToParams>) => {
    const replyToData = action.payload;
    state.reply = replyToData;
  },

  selectMessageItem: (
    state: ChatState,
    action: PayloadAction<SelectParams>,
  ) => {
    const { messageId, chatId, checked, align } = action.payload;

    if (state.select.messageIds.length > 9 && checked) {
      alertMessage({
        content: 'You can select up to 10 messages',
        messageType: 'error',
        alertType: 'snackbar',
        hasCloseIcon: true,
      });
    } else {
      const chatIndex = state.conversation[chatId].data.findIndex(
        (convo) => convo.id === messageId,
      );
      state.conversation[chatId].data[chatIndex].select = checked;

      if (checked) {
        state.select.messageIds.push(messageId);
        state.select.selectedAlign.push(align);
      } else {
        const index = state.select.messageIds.indexOf(messageId);
        if (index > -1) {
          state.select.messageIds.splice(index, 1); // 2nd parameter means remove one item only
          state.select.selectedAlign.splice(index, 1);
        }
      }
    }
  },

  cancelSelect: (state: ChatState, action: PayloadAction<SelectParams>) => {
    const { chatId } = action.payload;
    state.select.messageIds = [];
    const convoUpdated = state.conversation[chatId].data.map((convo) => ({
      ...convo,
      select: false,
    }));
    state.conversation[chatId].data = convoUpdated;
  },

  toggleForwardModal: (state: ChatState, action: PayloadAction<boolean>) => {
    const visible = action.payload;
    state.select.visible = visible;
  },

  resetReceiverList: (state: ChatState) => {
    state.receivers.list = [];
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(
      effects.setActiveRoomId.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload>) => {
        const { data } = action.payload;
        state.activeRoomId = Number(data);
      },
    )
    .addCase(
      effects.resetActiveRoomId.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload>) => {
        const { data } = action.payload;
        state.activeRoomId = Number(data);
      },
    )
    .addCase(
      effects.getRooms.pending,
      (
        state: ChatState,
        action: PayloadAction<RoomListWithIdPayload, any, any>,
      ) => {
        state.error = null;
        const { offset = 0, hasLoading = true } = action.meta.arg;
        if (offset === 0) {
          if (hasLoading) {
            state.rooms = [];
            state.isLoadingRooms = true;
          }
        } else {
          state.isLoadMoreRooms = true;
        }
      },
    )
    .addCase(
      effects.getRooms.fulfilled,
      (
        state: ChatState,
        action: PayloadAction<RoomListWithIdPayload, any, any>,
      ) => {
        const { data, error, message, offset } = action.payload;
        const { hasLoading = true } = action.meta.arg;

        if (error) {
          state.error = error;
        } else {
          if (hasLoading) {
            // uniq by id
            const concatRooms = uniq(
              state.rooms.concat(data.rooms),
              // eslint-disable-next-line no-shadow
              (data) => data.id,
            );
            state.rooms = concatRooms;
          } else {
            state.rooms = data.rooms;
          }
          state.requestCount = data.total_invited;
          state.unreadRequest = data?.unread_request;
          state.error = null;
        }

        if (offset === 0) {
          state.isLoadingRooms = false;
        } else {
          state.isLoadMoreRooms = false;
        }
        state.message = message;
      },
    )
    .addCase(
      effects.sendBulkChat.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload>) => {
        const { message, error } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      effects.deleteChatRoom.fulfilled,
      (
        state: ChatState,
        action: PayloadAction<RoomListWithIdPayload, any, any>,
      ) => {
        const { error, message, data } = action.payload;

        if (error) {
          state.error = error;
        } else {
          const { room_id: roomId } = data;

          // remove all data related to `roomId`
          const roomIdx = state.rooms.findIndex((room) => room.id === roomId);

          if (roomIdx > -1) {
            const newChats = [...state.rooms];
            newChats.splice(roomIdx, 1);
            // rearrange chat list
            state.rooms = newChats;
          }

          if (state.conversation[roomId]) {
            delete state.conversation[roomId];
          }

          if (state.lastMessage[roomId]) {
            delete state.lastMessage[roomId];
          }
        }

        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.readChat.fulfilled,
      (state: ChatState, action: PayloadAction<RoomListWithIdPayload>) => {
        const { error, message, roomId } = action.payload;

        if (error) {
          state.error = error;
        } else {
          const roomIdx = state.rooms.findIndex((room) => room.id == roomId);

          if (roomIdx > -1) {
            state.rooms[roomIdx].unread_count = 0;
            state.rooms[roomIdx].status.is_mentioned = false;
          }

          const requestIdx = state.requests.findIndex(
            (room) => room.id == roomId,
          );

          if (requestIdx > -1) {
            state.requests[requestIdx].unread_count = 0;
            state.requests[requestIdx].status.is_mentioned = false;
          }
        }
        state.message = message;
        state.isLoading = false;
      },
    )
    .addCase(
      effects.getRequestedRooms.pending,
      (
        state: ChatState,
        action: PayloadAction<RoomListWithIdPayload, any, any>,
      ) => {
        state.error = null;
        const { offset = 0, hasLoading = true } = action.meta.arg;
        if (offset === 0) {
          if (hasLoading) {
            state.requests = [];
            state.isLoadingRooms = true;
          }
        } else {
          state.isLoadMoreRooms = true;
        }
      },
    )
    .addCase(
      effects.getRequestedRooms.fulfilled,
      (state: ChatState, action: PayloadAction<RoomListWithIdPayload>) => {
        const { data, error, message, offset } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.requests.push(...data.rooms);
          state.error = null;
        }

        if (offset === 0) {
          state.isLoadingRooms = false;
        } else {
          state.isLoadMoreRooms = false;
        }
        state.message = message;
      },
    )
    .addCase(effects.acceptRequestedRoom.pending, (state: ChatState) => {
      state.error = null;
      state.isLoadAcceptRequest = true;
    })
    .addCase(
      effects.acceptRequestedRoom.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload>) => {
        const { message, error } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
        }
        state.isLoadAcceptRequest = false;
        state.message = message;
      },
    )
    .addCase(effects.rejectRequestedRoom.pending, (state: ChatState) => {
      state.error = null;
      state.isLoadRejectRequest = true;
    })
    .addCase(
      effects.rejectRequestedRoom.fulfilled,
      (state: ChatState, action: PayloadAction<RoomListWithIdPayload>) => {
        const { message, error, roomId } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
          const roomIdx = state.requests.findIndex((room) => room.id == roomId);

          if (roomIdx > -1) {
            const newChats = [...state.requests];
            newChats.splice(roomIdx, 1);
            // rearrange chat list
            state.requests = newChats;
          }

          if (state.conversation[roomId]) {
            delete state.conversation[roomId];
          }

          if (state.lastMessage[roomId]) {
            delete state.lastMessage[roomId];
          }
        }
        state.isLoadRejectRequest = false;
        state.message = message;
      },
    )
    .addCase(
      effects.forwardChat.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload>) => {
        const { error } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
        }
      },
    )

    // handle block unblock from entities/user
    .addCase(userEffects.blockUser.fulfilled, (state: ChatState, action) => {
      const { error } = action.payload;
      const { userId } = action.meta.arg;

      if (!error) {
        const roomIdx = state.rooms.findIndex(
          (room) => room.attributes?.personal?.user_id === userId,
        );
        if (roomIdx > -1) {
          state.rooms[roomIdx].attributes.personal.is_blocked = true;
        }
      }
    })
    .addCase(userEffects.unblockUser.fulfilled, (state: ChatState, action) => {
      const { error } = action.payload;
      const { userId } = action.meta.arg;

      if (!error) {
        const roomIdx = state.rooms.findIndex(
          (room) => room.attributes?.personal?.user_id === userId,
        );
        if (roomIdx > -1) {
          state.rooms[roomIdx].attributes.personal.is_blocked = false;
        }
      }
    })
    .addCase(
      effects.shareEventToChat.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload>) => {
        const { message, error } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
        }

        state.isLoading = false;
        state.message = message;
      },
    )
    .addCase(
      effects.muteNotification.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload, any, any>) => {
        const { error, message } = action.payload;
        const { roomId, isMuted } = action.meta.arg;

        if (error) {
          state.error = error;
        } else {
          const roomIdx = state.rooms.findIndex(
            (room) =>
              room.type === 'ROOM_TYPE_GROUP' &&
              room.id === parseInt(roomId, 10),
          );

          if (roomIdx >= 0) {
            state.rooms[roomIdx].status.is_muted = isMuted;
          }

          if (isMuted) {
            alertMessage({
              content: 'Notifications Muted',
              messageType: 'success',
              alertType: 'snackbar',
              hasCloseIcon: true,
            });
          } else {
            alertMessage({
              content: 'Notifications Unmuted',
              messageType: 'success',
              alertType: 'snackbar',
              hasCloseIcon: true,
            });
          }
        }

        state.message = message;
      },
    )
    .addCase(
      effects.getSearchReceivers.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload, any, any>) => {
        const { data, error } = action.payload;

        if (error) {
          state.error = error;
        } else {
          state.error = null;
          state.receivers.list = data.receivers;
        }
      },
    )
    .addCase(
      effects.respondGroupInvitation.pending,
      (
        state: ChatState,
        action: PayloadAction<RoomListWithIdPayload, any, any>,
      ) => {
        const { action: respondAction } = action.meta.arg;

        if (respondAction === 'ACTION_REJECT') {
          state.invitation.isLoadingReject = true;
        } else {
          state.invitation.isLoadingAccept = true;
        }
      },
    )
    .addCase(
      effects.respondGroupInvitation.fulfilled,
      (state: ChatState, action: PayloadAction<CommonPayload, any, any>) => {
        const { error } = action.payload;
        const { action: respondAction, roomId } = action.meta.arg;

        if (!error) {
          if (respondAction === 'ACTION_REJECT') {
            const roomIdx = state.requests.findIndex(
              (room) => room.id == roomId,
            );
            if (roomIdx > -1) {
              state.requests.splice(roomIdx, 1);
            }
          }
        }
        state.invitation.isLoadingReject = false;
        state.invitation.isLoadingAccept = false;
      },
    );
};

const chat = createSlice({
  name: 'chat',
  reducers,
  extraReducers,
  initialState,
});

export default chat;
