/* eslint-disable no-undef */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CommonPayload } from '../../../../@types/action-payload';
import {
  PeopleWatchlistPayload,
  PeopleWatchlistParam,
  CompanyWatchlistPayload,
  FollowUserParams,
  FollowUserPayload,
  FollowCompanyParams,
  AddCompanyWatchlistParams,
  AddCompanyWatchlistPayload,
} from './slice.types';

// APIs
import watchlistApi from 'lib/api/watchlist';

// Related stores
// import { effects as userEffects } from './user';

// Initial State
// This watchlist will be stored per user id
const initialState = {
  people: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  symbol: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  isLoading: false,
  message: '',
  error: null,
};

type WatchlistType = typeof initialState;

// selector
const watchlistSelector = (state) => state.entities.watchlist;
export const selectors = createSelector(watchlistSelector, (watchlist) => ({
  people: watchlist.people,
  symbol: watchlist.symbol,
}));

// Action types
const CONTEXT = '@redux/watchlist';

const actionType = {
  ADD_COMPANY_WATCHLIST: `${CONTEXT}/ADD_COMPANY_WATCHLIST`,
  GET_PEOPLE_WATCHLIST: `${CONTEXT}/GET_PEOPLE_WATCHLIST`,
  GET_SYMBOL_WATCHLIST: `${CONTEXT}/GET_SYMBOL_WATCHLIST`,
  FOLLOW_USER: `${CONTEXT}/FOLLOW_USER`,
  FOLLOW_COMPANY: `${CONTEXT}/FOLLOW_COMPANY`,
};

// Side effects
export const effects = {
  addCompanyWatchlist: createAsyncThunk<
    AddCompanyWatchlistPayload,
    AddCompanyWatchlistParams
  >(
    actionType.ADD_COMPANY_WATCHLIST,
    async ({ name, description, companyId }) => {
      const response = await watchlistApi.createNewWatchlist(name, description);

      if (!response.data) {
        throw new Error('Attempt to add company watchlist failed');
      }

      const { data, error, error_type, message } = response.data;

      if (error || error_type) {
        throw new Error(error || error_type);
      }

      return { data, message, companyId };
    },
  ),
  getCompanyWatchlist: createAsyncThunk<CompanyWatchlistPayload, number>(
    actionType.GET_SYMBOL_WATCHLIST,
    async (companyId) => {
      try {
        const response = await watchlistApi.getAllWatchlist(companyId);

        if (!response.data) {
          throw new Error('Attempt to get user company watchlist failed');
        }

        const { data, error, message } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, companyId };
      } catch (error) {
        return { error };
      }
    },
  ),
  followUser: createAsyncThunk<FollowUserPayload, FollowUserParams>(
    actionType.FOLLOW_USER,
    async ({ username, userid, type = 'FOLLOW' }) => {
      try {
        if (!userid) {
          throw new Error('User id is empty');
        }

        let targetAPI = watchlistApi.postFollowUser;

        if (type === 'UNFOLLOW') {
          targetAPI = watchlistApi.postUnfollowUser;
        }

        const response = await targetAPI(userid);

        if (!response.data) {
          throw new Error('Attempt to follow user failed');
        }
        const { error_type: error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        return { data, message, type, username, userid };
      } catch (error) {
        return { error };
      }
    },
  ),
  followCompany: createAsyncThunk<any, FollowCompanyParams>(
    actionType.FOLLOW_COMPANY,
    async ({ watchlistId, companyId, type = 'FOLLOW' }) => {
      if (!companyId && !watchlistId) {
        throw new Error('Missing parameters wathclist id or company id');
      }

      let targetAPI = watchlistApi.postFollowCompany;

      if (type === 'UNFOLLOW') {
        // @ts-ignore
        targetAPI = watchlistApi.postUnfollowCompany;
      }

      const response = await targetAPI(watchlistId, companyId);

      if (!response.data) {
        throw new Error('Attempt to follow company failed');
      }

      const { error_type, error, message, data } = response.data;

      if (error || error_type) {
        return { error, message };
      }

      return { data, message, error, watchlistId, companyId, type };
    },
  ),
};

// Reducers
const reducers = {};

const extraReducers = (builder) => {
  builder
    .addCase(effects.getCompanyWatchlist.pending, (state: WatchlistType) => {
      state.symbol.isLoading = true;
      state.symbol.error = null;
    })
    .addCase(
      effects.getCompanyWatchlist.fulfilled,
      (
        state: WatchlistType,
        action: PayloadAction<CompanyWatchlistPayload>,
      ) => {
        const { error, data, message, companyId } = action.payload;

        if (error) {
          state.symbol.error = error;
        } else {
          state.symbol.data[companyId] = data;
          state.error = null;
        }

        state.symbol.isLoading = false;
        state.symbol.message = message;
      },
    )
    .addCase(
      effects.getCompanyWatchlist.rejected,
      (
        state: WatchlistType,
        action: PayloadAction<CompanyWatchlistPayload>,
      ) => {
        state.symbol.error = action.payload.error;
        state.symbol.isLoading = false;
      },
    )
    .addCase(
      effects.followUser.fulfilled,
      (state: WatchlistType, action: PayloadAction<FollowUserPayload>) => {
        const { error, username, type } = action.payload;

        if (!error) {
          if (state.people.data[username]) {
            state.people.data[username][0].followed = type === 'FOLLOW' ? 1 : 0;
          }
        }
      },
    )

    // Create company watchlist
    .addCase(
      effects.addCompanyWatchlist.fulfilled,
      (
        state: WatchlistType,
        action: PayloadAction<AddCompanyWatchlistPayload>,
      ) => {
        const { data, error, message, companyId } = action.payload;

        if (!error) {
          if (!!companyId) {
            state.symbol.data[companyId].push(data);
          }
        }

        state.symbol.message = message;
      },
    )
    .addCase(
      effects.addCompanyWatchlist.rejected,
      (state: WatchlistType, action: PayloadAction<any>) => {
        // Do something when error occurred
      },
    )
    .addCase(
      effects.followCompany.fulfilled,
      (state: WatchlistType, action: PayloadAction<any>) => {
        const { data, error, watchlistId, companyId, type } = action.payload;
        if (!error) {
          const activeSymbol = state.symbol.data[companyId];

          if (activeSymbol) {
            const idx = activeSymbol.findIndex(
              (item) => item.watchlist_id === watchlistId,
            );

            if (idx !== -1) {
              const FOLLOWING = type === 'FOLLOW' ? 1 : 0;

              if (!FOLLOWING) {
                // Unfollow company
                // If unfollow all watchlist, unfollow from all child
                if (idx === 0) {
                  const newData = state.symbol.data[companyId].map((item) => ({
                    ...item,
                    followed: FOLLOWING,
                  }));
                  state.symbol.data[companyId] = newData;
                }
              } else {
                // If first time following, auto set following to all watchlist
                // Set all watchlist to following
                state.symbol.data[companyId][0].followed = FOLLOWING;
              }

              // If follow specific child, also add following to all watchlist
              // Update the selected wathclist item
              state.symbol.data[companyId][idx].followed = FOLLOWING;
            }
          }
        }
      },
    );
};

const watchlist = createSlice({
  name: 'watchlist',
  initialState,
  reducers,
  extraReducers,
});

export default watchlist;
