import { string, number, bool } from 'prop-types';

const schema = {
  app_id: '',
  user_hash: '',
  user_id: '',
  name: '',
  email: '',
  register_country: '',
  active_country: '',
  subs: '',
  created_at: 0,
  sms: 0,
  phone: 0,
  virtual_trading: false,
};

const propTypes = {
  app_id: string,
  user_hash: string,
  user_id: string,
  name: string,
  email: string,
  register_country: string,
  active_country: string,
  subs: string,
  created_at: number,
  sms: number,
  phone: number,
  virtual_trading: bool,
};

export default { schema, propTypes };
