import {
  BondBuyPreviewResponse,
  BondProductTypeEnum,
  BondSellPreviewResponse,
  BondTransactionActionEnum,
} from 'global/Securities/types/api/bond';
import color from '@deprecated-ui/_style/color';

import { ModalConfig } from './types';

export const FALLBACK_AVATAR_SRC = 'icon/fr-placeholder.svg';
export const STABLE_EARN_ICON_SRC = 'icon/fr/icon-stable-earn.svg';

// Enums
export enum ModalType {
  UNSPECIFIED = 'BOND_MODAL_UNSPECIFIED',
  TRANSACTION = 'BOND_MODAL_TRANSACTION',
  INFORMATION = 'BOND_MODAL_INFORMATION',
  CONFIRMATION = 'BOND_MODAL_CONFIRMATION',
  BLOCKERS = 'BOND_MODAL_BLOCKERS',
}

export enum BondInfoType {
  UNSPECIFIED = 'BOND_INFO_UNSPECIFIED',
  ACCRUED_INTEREST = 'BOND_INFO_ACCRUED_INTEREST',
  ACCRUED_INTEREST_SELLER = 'BOND_INFO_ACCRUED_INTEREST_SELLER',
  CAPITAL_GAIN_LOSS = 'BOND_INFO_CAPITAL_GAIN_LOSS',
  PNL_UNREALIZED = 'BOND_INFO_PNL_UNREALIZED',
  TOTAL_DAILY_COUPON = 'BOND_INFO_TOTAL_DAILY_COUPON',
  TOTAL_INVESTMENT_PROFIT = 'BOND_INFO_TOTAL_INVESTMENT_PROFIT',
  TOTAL_UNIT = 'BOND_INFO_TOTAL_UNIT',
  PROJECTED_RETURN = 'BOND_INFO_PROJECTED_RETURN',
  PNL_NETT = 'BOND_INFO_PNL_NETT',
  TOTAL_PROFIT_NETT = 'BOND_INFO_TOTAL_PROFIT_NETT',

  BUYING_PRICE = 'BOND_INFO_BUYING_PRICE',
  SELLING_PRICE = 'BOND_INFO_SELLING_PRICE',
  PROFIT = 'BOND_INFO_PROFIT',
  CASH_ON_HAND = 'BOND_INFO_CASH_ON_HAND',

  TAX = 'BOND_INFO_TAX',
  BOND_TAX = 'BOND_INFO_BOND_TAX',
  USER_COUPON = 'BOND_INFO_USER_COUPON',
}

export enum BondBlockerType {
  UNSPECIFIED = 'BOND_BLOCKER_UNSPECIFIED',
  MARKET_CLOSE = 'BOND_BLOCKER_MARKET_CLOSE',
  UNAVAILABLE = 'BOND_BLOCKER_UNAVAILABLE',
  TEMPORARY_UNAVAILABLE = 'BOND_BLOCKER_TEMPORARY_UNAVAILABLE',
  PRICE_CHANGED = 'BOND_BLOCKER_PRICE_CHANGED',
  MAINTENANCE = 'BOND_BLOCKER_MAINTENANCE',
  SHARIA_VALIDATION = 'BOND_BLOCKER_SHARIA_VALIDATION',
  STABLE_EARN = 'BOND_BLOCKER_STABLE_EARN',
  GENERAL_ERROR = 'BOND_BLOCKER_GENERAL_ERROR',
}

export enum BondsModalTypeEnum {
  UNSPECIFIED = 'BOND_MODAL_UNSPECIFIED',
  ASSETS = 'BOND_MODAL_ASSETS',
  BLOCKERS = 'BOND_MODAL_BLOCKERS',
  CONFIRMATION = 'BOND_MODAL_CONFIRMATION',
  HISTORY = 'BOND_MODAL_HISTORY',
  ORDER = 'BOND_MODAL_ORDER',
  PORTFOLIO = 'BOND_MODAL_PORTFOLIO',
  TNC = 'BOND_MODAL_TNC',
  TRANSACTION = 'BOND_MODAL_TRANSACTION',
  ORDER_PLACED = 'BOND_MODAL_ORDER_PLACED',
  COUPON_RECEIPT = 'BOND_MODAL_COUPON_RECEIPT',
}

// Modal Config
export const BOND_TRANSACTION_CONFIG: Record<
  BondTransactionActionEnum,
  ModalConfig
> = {
  [BondTransactionActionEnum.UNSPECIFIED]: {
    title: '',
    textColor: '#333333',
    content: [],
  },
  [BondTransactionActionEnum.BUY]: {
    title: 'Buy',
    textColor: color.primary,
    content: [],
  },
  [BondTransactionActionEnum.SELL]: {
    title: 'Sell',
    textColor: color.sunsetOrange,
    content: [],
  },
};

export const bondsErrorTypesMapping: Record<string, BondBlockerType> = {
  product_not_found: BondBlockerType.UNAVAILABLE,
  price_rate_not_found: BondBlockerType.UNAVAILABLE,
  fr_in_record_date_period: BondBlockerType.TEMPORARY_UNAVAILABLE,
  fr_bonds_market_close: BondBlockerType.MARKET_CLOSE,
  price_has_changed: BondBlockerType.PRICE_CHANGED,
  user_sharia_validation: BondBlockerType.SHARIA_VALIDATION,
  stable_earn_cannot_be_sold_outside_bibit: BondBlockerType.STABLE_EARN,

  // The UI is not available yet, we use the alert messages
  // 'stockbit_get_account_not_found',
  // 'trading_account_suspended',
  // 'fr_existing_order_inprogress',
  // 'portfolio_not_exist',
  // 'redeem_amount_greater_than_redeemable_amount',
  // 'not_in_secondary_market_period',
  // 'sell_order_inprogress',
};

// Transactions Config
export const MAX_UNIT_AMOUNT = 9_999_999;
export const DEBOUNCE_DURATION = 500; // 500ms or 0.5 seconds
export const BASE_UNIT_AMOUNT = 1_000_000; // Base unit amount is 1 million rupiah

// Initial states
export const initialBuyPreview: BondBuyPreviewResponse = {
  product: {
    type: BondProductTypeEnum.FR,
    id: '',
    name: '',
    min_order: '',
    icon_url: '',
  },
  unit: '0',
  rdn: {
    account_name: '',
    bank: {
      id: '0',
      name: '',
      color_hex: '',
    },
    balance: 0,
  },
  settlement_date: '',
  accrued_interest: 0,
  buy_price_amount: 0,
  invested_amount: 0,
  price_rate: 0,
  estimation_amount: 0,
  total_amount: 0,
  fee: {
    stamp_duty: 0,
    tax: 0,
  },
};

export const initialSellPreview: BondSellPreviewResponse = {
  action: BondTransactionActionEnum.SELL,
  available_units: 0,
  units: 0,
  sell_amount: 0,
  accrued_interest: 0,
  amount: 0,
  profit_loss: 0,
  price: {
    deal: 0,
    markup: 0,
    buy: 0,
    sell: 0,
  },
  fee: {
    stamp_duty: 0,
    capital_gain_tax: 0,
    accrued_interest_tax: 0,
    commission_fee: 0,
    total_tax: 0,
    tax_percent: 0,
    accrued_interest_exclude_tax: 0,
    estimation_amount_exclude_tax: 0,
  },
  product: {
    type: BondProductTypeEnum.FR,
    id: '',
    name: '',
    min_order: '0',
    icon_url: '',
  },
  acquisition: {
    price: 0,
    date: null,
    order_id: '',
  },
  profit_loss_nett: 0,
  daily_accrued_interest: 0,
  invested_amount: 0,
  accrued_interest_exclude_tax: 0,
  estimation_amount_exclude_tax: 0,
  settlement_date: null,
  next_coupon_date: null,
  last_coupon_date: null,
  estimation_sell_amount: 0,
  estimation_disburse_amount: 0,
  capital_gain_loss_nett: 0,
  profit_loss_nett_percentage: 0,
  seller_coupon: 0,
  total_realized_amount: 0,
  total_realized_amount_percentage: 0,
  transaction_pnl: 0,
};
