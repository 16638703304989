export const CLEVERTAP_EVENT_NAME = {
  SIDE_MENU: 'side_menu_action',
  HEADER_MENU: 'header_menu_action',
  COMPANY_MENU: 'company_action',
};
// List of stockbit securities clevertap event name
// https://www.notion.so/stockbit/Stockbit-Buy-Sell-Order-Web-e129c61e0ae64d70abdb0d1811c5ec71

/// //////// Cancel Order and Amend Event ///////////
export const ORDER_DETAIL_CANCEL_ORDER_SELECTED =
  'Order Detail Cancel Order Selected';
export const ORDER_DETAIL_CANCEL_ORDER_VIEWED =
  'Order Detail Cancel Order Viewed';
export const ORDER_DETAIL_CANCEL_ORDER_CONFIRMED =
  'Order Detail Cancel Order Confirmed';
export const ORDER_DETAIL_AMEND_SELECTED = 'Order Detail Amend Selected';
export const AMEND_ORDER_VIEWED = 'Amend Order Viewed';
export const AMEND_ORDER_QUANTITY_ADJUSTED = 'Amend Order Quantity Adjusted';
export const AMEND_ORDER_PLACED = 'Amend Order Placed';
export const AMEND_ORDER_PREVIEWED = 'Amend Order Previewed';
export const AMEND_ORDER_CANCELED = 'Amend Order Canceled';
export const AMEND_ORDER_CONFIRMED = 'Amend Order Confirmed';
export const AMEND_ORDER_RECEIVED = 'Amend Order Received';
export const AMEND_ORDER_DONE = 'Amend Order Done';

/// //////// Buy Order Event ///////////
export const BUY_ORDER_QUANTITY_ADJUSTED = 'Buy Order Quantity Adjusted';
export const BUY_ORDER_PLACED = 'Buy Order Placed';
export const BUY_ORDER_PREVIEWED = 'Buy Order Previewed';
export const BUY_ORDER_VIEWED = 'Buy Order Viewed';
export const BUY_ORDER_CANCELED = 'Buy Order Canceled';
export const BUY_ORDER_CONFIRMED = 'Buy Order Confirmed';
export const BUY_ORDER_RECEIVED = 'Buy Order Received';
export const BUY_ORDER_DONE = 'Buy Order Done';
export const BUY_ERROR_PAGE = 'Buy Error Page';
export const CHANGE_STOCK_VIEWED = 'Change Stock Viewed';
export const PORTFOLIO_BUY_MORE_SELECTED = 'Portfolio Buy More Selected';

/// //////// Sell Order Event ///////////
export const SELL_ORDER_QUANTITY_ADJUSTED = 'Sell Order Quantity Adjusted';
export const SELL_ORDER_PLACED = 'Sell Order Placed';
export const SELL_ORDER_PREVIEWED = 'Sell Order Previewed';
export const SELL_ORDER_VIEWED = 'Sell Order Viewed';
export const SELL_ORDER_CANCELED = 'Sell Order Canceled';
export const SELL_ORDER_CONFIRMED = 'Sell Order Confirmed';
export const SELL_ORDER_RECEIVED = 'Sell Order Received';
export const SELL_ORDER_DONE = 'Sell Order Done';

/// //////// Exchange Fee Event ///////////
export const EXCHANGE_FEE_SELECTED = 'Exchange Fee Selected';
export const EXCHANGE_FEE_VIEWED = 'Exchange Fee Viewed';

/// //////// Portfolio Event ///////////
export const PORTFOLIO_VIEWED = 'Portfolio Viewed';
export const PORTFOLIO_ORDER_VIEWED = 'Portfolio Order Viewed';
export const PORTFOLIO_SELL_STOCK_SELECTED = 'Portfolio Sell Stock Selected';
export const PORTFOLIO_COMPANY_SELL_STOCK_SELECTED = 'Company Sell Stock Selected';
export const PORTFOLIO_STOCK_DETAIL_VIEWED = 'Portfolio Stock Detail Viewed';

/// //////// Order List Page Event ///////////
export const ORDER_VIEWED = 'Order viewed';
export const ORDER_DETAIL_VIEWED = 'Order Detail Viewed';
export const STOCK_SELECTED = 'Stock Selected';

/// //////// Order Exercise Event ///////////
export const PORTFOLIO_EXERCISE_CLICKED = 'Portfolio Exercise Clicked';
export const PORTFOLIO_DETAIL_EXERCISE_SELECTED =
  'Portfolio Detail Exercise Selected';
export const EXERCISE_VIEWED = 'Exercise Viewed';
export const EXERCISE_SELECTED = 'Exercise Selected';
export const EXERCISE_ERROR_PAGE = 'Exercise Error Page';
export const EXERCISE_ORDER_PREVIEWED = 'Exercise Order Previewed';
export const EXERCISE_ORDER_CONFIRMED = 'Exercise Order Confirmed';
export const EXERCISE_ORDER_CANCELED = 'Exercise Order Canceled';
export const TRANSACTION_ORDER_FAILED = 'Transaction Order Failed';
export const TRANSACTION_ORDER_FAILED_CONFIRMED =
  'Transaction Order Failed Confirmed';
export const TRANSACTION_ORDER_ALERT = 'Transaction Order Alert';
export const BUY_STOCK_CONTINUE = 'Buy Stock Continue';

/// //////// Chart Event ///////////
export const PORTFOLIO_VIEW_CHART_SELECTED = 'Portfolio View Chart Selected';
export const PORTFOLIO_SET_PRICE_ALERT_SELECTED =
  'Portfolio Set Price Alert Selected';

/// //////// Day Trade Event ///////////
export const DAY_TRADE_ACTION = 'Day Trade Action';
export const AUTO_BUY_ACTION = 'Auto Buy Action';

export const DAY_TRADE_ACTIVATION_VIEWED = 'Day Trade Activation Viewed';
export const DAY_TRADE_TNC_VIEWED = 'Tnc Day Trade Viewed';
export const DAY_TRADE_ACTIVATION_SUCCESS_VIEWED = 'Success Activated Day Trade Viewed';

export const DAY_TRADE_ACTIVATION_SELECTED = 'Day Trade Activated Now Selected';
export const DAY_TRADE_ACTIVATION_DISMISSED = 'Day Trade Activated Later Selected';
export const DAY_TRADE_ACTIVATION_SUBMITTED = 'TnC Day Trade Lanjut Selected';
export const DAY_TRADE_ACTIVATION_SUCCESS_SELECTED = 'Day Trade Success Activated Selected';

export const DAY_TRADE_LEVERAGE_OPTION_VIEWED = 'Balance Setting Viewed';
export const DAY_TRADE_LEVERAGE_ACTIVATION_SELECTED = 'Day Trade Aktifkan Selected';

export const DAY_TRADE_ALERT_AFTER_MARKET_VIEWED = 'Alert After Hour Day Trade Viewed';
export const DAY_TRADE_ALERT_DEBT_RATIO_VIEWED = 'Alert Force Sell Day Trade Viewed';
export const DAY_TRADE_ALERT_DEBT_RATIO_SUBMITTED = 'Alert Force Sell Day Trade Selected';

export const DAY_TRADE_DEBT_RATIO_CHIP_SELECTED = 'Debt Ratio Selected';
export const DAY_TRADE_DEBT_RATIO_MODAL_VIEWED = 'Debt Ratio Viewed';

export const DAY_TRADE_STOCK_LIST_VIEWED = 'Day Trade List Viewed';
export const DAY_TRADE_STOCK_SORT_VIEWED = 'Day Trade Sort By Viewed';

export const DAY_TRADE_LEVERAGE_SELECTED = 'Set Leverage Selected';

export const DAY_TRADE_SORT_SELECTED = 'Sort By Day Trade Selected';
export const DAY_TRADE_SORT_APPLIED = 'Apply Sort Day Trade';

export const DAY_TRADE_ORDER_TYPE_SELECTED = 'Auto Buy Order Type Selected';
export const DAY_TRADE_SPLIT_ORDER_SELECTED = 'Split Order Toggle Selected';

export const DAY_TRADE_ORDER_DETAIL_SELECTED = 'Order Detail Selected';

export const DAY_TRADE_SET_VISIBLE_BALANCE = 'Set Visible Balance';

export const SECURITIES_TRADING_PIN_VIEWED = 'Trading PIN Login Page Viewed';
export const SECURITIES_TRADING_PIN_SUBMITTED = 'Trading PIN Loggen In';

export const SECURITIES_AUTO_ORDER_OPTIONS_VIEWED = 'Auto Buy Order Type Viewed';

export const SECURITIES_ORDER_TAB_SELECTED = 'Order Transaction Type Selected';
