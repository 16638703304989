import { getSecuritiesAPIUrl } from '../utils/apiBaseUrl';
import { authEndpoint } from '../constants/apiEndpoint';
import { AuthRefreshResponse } from '../types/api/auth';
import { Response } from '../../../../@types/api-response';
import { refreshApiInstance } from './instance/refresh';

export const postAuthRefresh = async (): Promise<AuthRefreshResponse> => {
  const { data: axiosData } = await refreshApiInstance.post<
    Response<AuthRefreshResponse>
  >(`${getSecuritiesAPIUrl()}${authEndpoint.authRefresh}`);

  return axiosData.data;
};
