import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import SUCCESS_MESSAGE from 'constants/successMessage';
import handleErrorMessageAPI from 'global/AlertErrorMessage';

// APIs
import userApi from 'lib/api/user';
import alertMessage from 'global/AlertMessage';

// Initial state
const initialState = {
  toggleTopUser: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  toggleBlockUser: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
  suspendUser: {
    data: {},
    isLoading: false,
    error: null,
    message: '',
  },
};

// Selectors
export const selectors = createSelector(
  (state) => state.entities.moderation,
  (moderation) => ({
    toggleTopUser: moderation.toggleTopUser,
    toggleBlockUser: moderation.toggleBlockUser,
  }),
);

// Action types
const CONTEXT = '@redux/moderation';

const actionType = {
  BLOCK_USER: `${CONTEXT}/BLOCK_USER`,
  UNBLOCK_USER: `${CONTEXT}/UNBLOCK_USER`,
  SUSPEND_USER: `${CONTEXT}/SUSPEND_USER`,
};

// effects
export const effects = {
  // Block unblock user
  blockUser: createAsyncThunk(actionType.BLOCK_USER, async (userId) => {
    try {
      const response = await userApi.blockUser(userId);

      if (!response) {
        throw new Error('Attempt to block user failed');
      }

      const { error, data, message } = response.data;

      if (error) {
        return { error, message };
      }

      alertMessage({
        content: message,
        alertType: 'snackbar',
        messageType: 'success',
        hasCloseIcon: true,
      });

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),
  unblockUser: createAsyncThunk(actionType.UNBLOCK_USER, async (userId) => {
    try {
      const response = await userApi.unblockUser(userId);

      if (!response) {
        throw new Error('Attempt to block user failed');
      }

      const { error, data, message } = response.data;

      if (error) {
        return { error, message };
      }

      handleErrorMessageAPI(message, SUCCESS_MESSAGE.ALERT_GREEN);

      return { data, message };
    } catch (error) {
      return { error };
    }
  }),

  // suspend user
  suspendUser: createAsyncThunk(
    actionType.SUSPEND_USER,
    async (suspendData) => {
      try {
        const response = await userApi.suspendUser(suspendData);

        if (!response.data) {
          throw new Error('Attempt to suspend user failed');
        }

        const { error, message, data } = response.data;

        if (error) {
          return { error, message };
        }

        alertMessage({
          content: message,
          alertType: 'plain',
          messageType: 'success',
        });

        return { data, message };
      } catch (error) {
        return { error };
      }
    },
  ),
};

// Reducers
const reducers = {};

const extraReducers = {
  [effects.blockUser.pending]: (state) => {
    state.toggleBlockUser.isLoading = true;
    state.toggleBlockUser.error = null;
  },
  [effects.blockUser.fulfilled]: (state, action) => {
    const { data, error, message } = action.payload;
    if (error) {
      state.toggleBlockUser.error = error;
    } else {
      state.toggleBlockUser.data = data;
    }

    state.toggleBlockUser.message = message;
    state.toggleBlockUser.isLoading = false;
  },
  [effects.blockUser.rejected]: (state, action) => {
    state.toggleBlockUser.error = action.payload.error;
    state.toggleBlockUser.isLoading = false;
  },
  [effects.unblockUser.pending]: (state) => {
    state.toggleBlockUser.isLoading = true;
    state.toggleBlockUser.error = null;
  },
  [effects.unblockUser.fulfilled]: (state, action) => {
    const { data, error, message } = action.payload;
    if (error) {
      state.toggleBlockUser.error = error;
    } else {
      state.toggleBlockUser.data = data;
    }

    state.toggleBlockUser.message = message;
    state.toggleBlockUser.isLoading = false;
  },
  [effects.unblockUser.rejected]: (state, action) => {
    state.toggleBlockUser.error = action.payload.error;
    state.toggleBlockUser.isLoading = false;
  },

  // Suspend user
  [effects.suspendUser.pending]: (state) => {
    state.suspendUser.isLoading = true;
    state.suspendUser.error = null;
  },
  [effects.suspendUser.fulfilled]: (state, action) => {
    const { data, error, message } = action.payload;
    if (error) {
      state.suspendUser.error = error;
    } else {
      state.suspendUser.data = data;
    }

    state.suspendUser.message = message;
    state.suspendUser.isLoading = false;
  },
  [effects.suspendUser.rejected]: (state, action) => {
    state.suspendUser.error = action.payload.error;
    state.suspendUser.isLoading = false;
  },
};

const moderation = createSlice({
  name: 'moderation',
  initialState,
  reducers,
  extraReducers,
});

export default moderation;
