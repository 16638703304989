/* eslint-disable no-plusplus */
/* eslint-disable no-console */
import {
  createSelector,
  createSlice,
  createAction,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import { SendMessage } from '@stockbitgroup/protos/social/wssocial/entity/message/v1/send_pb';
import { ReceivedMessage } from '@stockbitgroup/protos/social/wssocial/entity/message/v1/received_pb';

import {
  DATA_WS_LOGGER,
  SEND_MESSAGE_TYPE,
  WEBSOCKET_INTERVAL_PING_TIME,
  WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION,
} from 'features/chat/constant';

import {
  getErrorNoReconnectionWsSocial,
  getIdIntervalPingWsSocial,
  removeIdIntervalPingWsSocial,
  saveIdIntervalPingWsSocial,
  storeErrorNoReconnectionWsSocial,
} from 'utils/socketSocialLocalStorage';

import { insertQueue } from 'global/WebSocketV2/Utils/queuingProcess';

import wsApi from 'lib/api/ws';
import auth from 'utils/auth';

import { isConnectionOpen, wsLogger } from './utils';
import { subscribeToRoomList } from './channels/roomList';
import { subscribeToChatRooms } from './channels/chatRoom';

const initialState = {
  websocketV2: {
    validated: false,
    error: null,
  },
  websocketSocialV2: {
    data: {},
  },
};

type WebsocketState = typeof initialState;
const wsSelector = (state) => state.websocketV2;

// selectors
export const selectors = createSelector(wsSelector, (ws: WebsocketState) => ({
  ...ws,
}));

// Ws Social V2
export const chatWsSocialV2Selector = createSelector(
  (state) => state.websocketV2.websocketSocialV2.data,
  (state) => state,
);

// Actions
const CONTEXT = '@redux/webSocketV2';

const actionType = {
  INIT_WEBSOCKETSOCIALV2: `${CONTEXT}/INIT_WEBSOCKETSOCIALV2`,
  INCOMING_MESSAGE_WEBSOCKETSOCIALV2: createAction(
    `${CONTEXT}/INCOMING_MESSAGE_WEBSOCKETSOCIALV2`,
  ),
  RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2: `${CONTEXT}/RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2`,
  DO_RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2: createAction(
    `${CONTEXT}/DO_RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2`,
  ),
};

export const actions = actionType;

// API actions
export const getWskey = async () => {
  try {
    const response = await wsApi.getWSTradingKey();

    if (!response.data) {
      throw new Error('Attempt to get wskey failed');
    }

    const { error, message, data } = response.data;

    if (error) {
      // @ts-ignore
      throw new Error({ error, message });
    }

    return data.key;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const pingDispatcher = (webSocketSocialV2) => () => {
  const { readyState } = webSocketSocialV2;

  if (!webSocketSocialV2) {
    console.log('socket did not exists');
    return;
  }

  const payload = SendMessage.fromJson({
    type: SEND_MESSAGE_TYPE.TYPE_PING,
  }).toBinary();

  if (isConnectionOpen(readyState)) {
    webSocketSocialV2.send(payload);
  }
};

const pingWSSocial = (dispatch, webSocketSocialV2) => {
  const idInterval = setInterval(
    () => dispatch(pingDispatcher(webSocketSocialV2)),
    WEBSOCKET_INTERVAL_PING_TIME,
  );
  // store ping id interval to localStorage
  saveIdIntervalPingWsSocial(idInterval);
};

export const clearIntervalPingWSSocial = () => {
  const idInterval = getIdIntervalPingWsSocial();

  if (idInterval) {
    wsLogger({
      module: DATA_WS_LOGGER.MODULE_NAME,
      action: DATA_WS_LOGGER.ACTION.CLEAR_PING_INTERVAL,
      payload: idInterval,
    });

    // clear interval ping ws social
    clearInterval(parseInt(idInterval));
    // remove ping id interval in localStorage
    removeIdIntervalPingWsSocial();
  }
};

export const effects = {
  initWebsocketSocialV2: createAsyncThunk<any, any>(
    actionType.INIT_WEBSOCKETSOCIALV2,
    async (
      { webSocketSocialV2, createSocialSocket },
      { dispatch, getState },
    ) => {
      if (!webSocketSocialV2) return;
      // @ts-ignore
      const wsSocialV2 = webSocketSocialV2 as any;
      const {
        // @ts-ignore
        entities: {
          credentials: { isLoggedIn: isUserLogin },
        },
      } = getState();

      const parsedCookie = auth.getToken();
      const {
        accessToken: { token },
      } = parsedCookie;

      if (!isUserLogin || !token) {
        return false;
      }

      if (token) {
        wsSocialV2.onopen = async () => {
          wsLogger({
            module: DATA_WS_LOGGER.MODULE_NAME,
            action: DATA_WS_LOGGER.ACTION.OPEN_CONNECTION,
          });

          // to keep websocket connection keep alive
          pingWSSocial(dispatch, webSocketSocialV2);

          // @ts-ignore
          const activeRoomId = Number(getState()?.entities?.chat?.activeRoomId);

          // subscribe to current active chat room
          if (activeRoomId > 0) {
            dispatch(subscribeToChatRooms([activeRoomId]));
          }

          // subscribe to room list channel
          dispatch(subscribeToRoomList());

          // initial state, allowed reconnection
          storeErrorNoReconnectionWsSocial(
            WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION.ALLOWED,
          );
        };

        wsSocialV2.onmessage = async ({ data }) => {
          const buffer = Buffer.from(data, 'base64');
          const payload: any = ReceivedMessage.fromBinary(buffer).toJson();

          if (payload.type === SEND_MESSAGE_TYPE.TYPE_BROADCAST) {
            await insertQueue(payload).then((data) => {
              wsLogger({
                module: DATA_WS_LOGGER.MODULE_NAME,
                action: DATA_WS_LOGGER.ACTION.INCOMING_MESSAGE,
                payload: data,
              });
              dispatch(
                // @ts-ignore
                actionType.INCOMING_MESSAGE_WEBSOCKETSOCIALV2({
                  data,
                }),
              );
            });
          } else {
            dispatch(
              // @ts-ignore
              actionType.INCOMING_MESSAGE_WEBSOCKETSOCIALV2({
                data: payload,
              }),
            );
          }
        };

        wsSocialV2.onerror = async (err) => {
          wsLogger({
            module: DATA_WS_LOGGER.MODULE_NAME,
            action: DATA_WS_LOGGER.ACTION.ERROR_CONNECTION,
            logLevel: DATA_WS_LOGGER.LOG_LEVEL.ERROR,
            payload: err,
          });

          wsSocialV2.close();

          dispatch(
            // @ts-ignore
            actionType.DO_RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2(),
          );

          const isAllowedReconnection = !JSON.parse(
            getErrorNoReconnectionWsSocial(),
          );

          if (isAllowedReconnection) {
            // set to NOT allowed reconnection
            storeErrorNoReconnectionWsSocial(
              WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION.NOT_ALLOWED,
            );
          }
        };

        wsSocialV2.onclose = () => {
          wsLogger({
            module: DATA_WS_LOGGER.MODULE_NAME,
            action: DATA_WS_LOGGER.ACTION.CLOSE_CONNECTION,
            logLevel: DATA_WS_LOGGER.LOG_LEVEL.WARN,
          });

          // reconnection
          const isAllowedReconnection = !JSON.parse(
            getErrorNoReconnectionWsSocial(),
          );

          wsLogger({
            module: DATA_WS_LOGGER.MODULE_NAME,
            action: DATA_WS_LOGGER.ACTION.CHECK_ALLOWED_RECONNECTION,
            logLevel: DATA_WS_LOGGER.LOG_LEVEL.WARN,
            payload: isAllowedReconnection,
          });

          // clear inverval ping WS Social
          clearIntervalPingWSSocial();

          dispatch(
            // @ts-ignore
            actionType.DO_RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2(),
          );

          if (isAllowedReconnection) {
            // reconnection
            createSocialSocket();
          }

          wsSocialV2.removeEventListener('message', null);
        };
      }
    },
  ),
  resetWebsocketSocialV2: createAsyncThunk<any>(
    actionType.RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2,
    (_, { dispatch }) => {
      dispatch(
        // @ts-ignore
        actionType.DO_RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2(),
      );
    },
  ),
};

const reducers = {
  clearChat: (state) => {
    state.websocketSocialV2.data = {};
  },
};

const extraReducers = (builder) => {
  builder
    .addCase(actionType.INCOMING_MESSAGE_WEBSOCKETSOCIALV2, (state, action) => {
      const { data } = action.payload;

      const sendMessageType = data?.type;

      // only type broadcast
      if (sendMessageType === SEND_MESSAGE_TYPE.TYPE_BROADCAST) {
        state.websocketSocialV2.data = data;
      }
    })
    .addCase(
      actionType.DO_RESET_INCOMING_MESSAGE_WEBSOCKETSOCIALV2,
      (state) => {
        state.websocketSocialV2.data = {};
      },
    );
};

const webSocketSlice = createSlice({
  name: 'websocketV2',
  initialState,
  reducers,
  extraReducers,
});

export default webSocketSlice;
