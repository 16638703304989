import { AxiosError } from 'axios';
import { Response } from '../../../@types/api-response';

export const getErrorConfig = (
  error: AxiosError<Response>,
): TradingErrorConfig => {
  const { data, status } = error.response || {};
  const { error_type: type, message } = data || {};

  return { status, type, message };
};
