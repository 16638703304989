import { UserTargetAPI } from 'constants/trading';
import { TnCFeaturesIDEnum } from 'global/Securities/types/termsAndCondition';

export const authEndpoint = <const>{
  authRefresh: '/auth/refresh',
};

export const portfolioEndpoint = <const>{
  portfolio: {
    [UserTargetAPI.LEGACY]: '/portfolio',
    [UserTargetAPI.NEW_CORE]: '/securities/portfolio/v1/list',
  },
  portfolioDetail: {
    [UserTargetAPI.LEGACY]: (symbol: string) =>
      `/portfolio/detail?symbol=${symbol}`,
    [UserTargetAPI.NEW_CORE]: (symbol: string) =>
      `/portfolio/v2/detail?stock_code=${symbol}`,
  },
};

export const emittenEndpoint = <const>{
  emittenInfo: (symbol: string) => `/emitten/${symbol}/info`,
};

export const orderbookEndpoint = <const>{
  orderbookCompanies: (symbol: string) => `/company-price-feed/v2/orderbook/companies/${symbol}`,
};

export const maintenanceEndpoint = <const>{
  getStatus: '/oms/auth/maintenance/status',
};

export const orderEndpoint = <const>{
  bulkCancelByOrderId: '/order/v2/bulk-cancel',
  orderDetail: '/order/v2/detail',
};

export const termsAndConditionsEndpoint = <const>{
  getTnCByFeature: (featureId: number) => `/tnc/${featureId}`,
  postTnCAcceptance: () => '/tnc/acceptance',
};

// terms and condition v2
export const termsAndConditionsAPI = <const>{
  getTnCByFeature: (featureIds: TnCFeaturesIDEnum[]) => {
    const featuresQs = featureIds.map((f) => `feature_ids=${f}`).join('&');
    return `/tnc/v2/get?${featuresQs}`;
  },
  postTnCAcceptance: () => '/tnc/v2/acceptance',
};

export const tradingEndpoints = <const>{
  getTradingInfo: '/trading/info',
};

export const bondsEndpoints = <const>{
  postBuy: '/bond/v1/buy',
  getBuyPreview: '/bond/v1/buy/preview',
  getOrderList: '/bond/v1/orders',
  getOrderDetail: (id: string) => `/bond/v1/orders/${id}`,
  getPortfolioList: '/bond/v1/portfolio',
  getPortfolioDetail: (code: string) => `/bond/v1/portfolio/${code}`,
  postSell: '/bond/v1/sell',
  getSellPreview: '/bond/v1/sell/preview',
};
