import { isClient } from 'constants/app';
import {
  SECURITIES_ACCESS_TOKEN,
  SECURITIES_REFRESH_TOKEN,
  SECURITIES_ACCOUNT_TYPE,
  SECURITIES_ACCOUNT_NUMBER,
  SECURITIES_MAIN_ACCOUNT_NUMBER,
  TRADE_IS_LOGGED_IN,
  TRADING_ACCOUNT_TYPE,
  LAST_TRADING_ACCOUNT_TYPE,
  TRADING_BROKER,
} from 'constants/securitiesLocalStorage';
import { ACCOUNT_TYPE } from 'global/TradingModal/RealTradingModal/types';

function getSecuritiesToken() {
  const tokens: {
    securitiesAccessToken: null | string;
    securitiesRefreshToken: null | string;
  } = {
    securitiesAccessToken: null,
    securitiesRefreshToken: null,
  };

  if (isClient()) {
    tokens.securitiesAccessToken = localStorage.getItem(
      SECURITIES_ACCESS_TOKEN,
    );
    tokens.securitiesRefreshToken = localStorage.getItem(
      SECURITIES_REFRESH_TOKEN,
    );
  }

  return tokens;
}

function getTradingAccountType() {
  if (!isClient()) {
    return null;
  }
  const tradingAccountType = localStorage.getItem(
    TRADING_ACCOUNT_TYPE,
  ) as ACCOUNT_TYPE;
  return tradingAccountType;
}

function saveSecuritiesToken({
  securitiesAccessToken,
  securitiesRefreshToken,
}) {
  if (isClient()) {
    localStorage.setItem(SECURITIES_ACCESS_TOKEN, securitiesAccessToken);
    localStorage.setItem(SECURITIES_REFRESH_TOKEN, securitiesRefreshToken);

    localStorage.setItem(TRADE_IS_LOGGED_IN, 'true');
    localStorage.setItem(TRADING_ACCOUNT_TYPE, 'real');
    localStorage.setItem(LAST_TRADING_ACCOUNT_TYPE, 'real');
    localStorage.setItem(TRADING_BROKER, 'stockbit');
  }

  // Token for flagging that user is logged in
}

function saveSecuritiesAccountInfo({ accountType, mainAccountNumber, accountNumber }) {
  if (isClient()) {
    localStorage.setItem(SECURITIES_ACCOUNT_TYPE, accountType);
    localStorage.setItem(SECURITIES_ACCOUNT_NUMBER, accountNumber);
    localStorage.setItem(SECURITIES_MAIN_ACCOUNT_NUMBER, mainAccountNumber);
  }
}

function getSecuritiesAccountInfo() {
  if (isClient()) {
    const accountType = localStorage.getItem(SECURITIES_ACCOUNT_TYPE);
    const accountNumber = localStorage.getItem(SECURITIES_ACCOUNT_NUMBER);
    const mainAccountNumber = localStorage.getItem(SECURITIES_MAIN_ACCOUNT_NUMBER);
    return {
      accountType,
      accountNumber,
      mainAccountNumber,
    };
  }
  return {
    accountType: null,
    accountNumber: null,
    mainAccountNumber: null,
  };
}

function switchToVirtualTrading() {
  if (isClient()) {
    localStorage.setItem(TRADE_IS_LOGGED_IN, 'true');
    localStorage.setItem(TRADING_ACCOUNT_TYPE, 'virtual');
    localStorage.setItem(TRADING_BROKER, 'virtual');
  }
}

function logoutTrading() {
  const keysToRemove = [
    TRADE_IS_LOGGED_IN,
    TRADING_ACCOUNT_TYPE,
    TRADING_BROKER,
    SECURITIES_ACCESS_TOKEN,
    SECURITIES_REFRESH_TOKEN,
    SECURITIES_ACCOUNT_TYPE,
    SECURITIES_MAIN_ACCOUNT_NUMBER,
    SECURITIES_ACCOUNT_NUMBER,
    LAST_TRADING_ACCOUNT_TYPE,
  ];

  if (isClient()) {
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }
}

export default {
  getTradingAccountType,
  getSecuritiesToken,
  saveSecuritiesToken,
  switchToVirtualTrading,
  logoutTrading,
  saveSecuritiesAccountInfo,
  getSecuritiesAccountInfo,
};
