import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { API_TRADING_URL } from 'constants/api';
import securitiesLocalStorage from 'utils/securitiesLocalStorage';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const { method, headers, url, data } = config;
  const { securitiesRefreshToken } =
    securitiesLocalStorage.getSecuritiesToken();
  const isPostRequest = method === 'POST' || method === 'post';
  const isTradingAPI = url.includes(API_TRADING_URL) || url[0] === '/';

  headers.Authorization =
    headers.Authorization || `Bearer ${securitiesRefreshToken}`;

  if (isPostRequest && isTradingAPI) {
    headers['content-type'] = 'application/x-www-form-urlencoded';
    // eslint-disable-next-line no-param-reassign
    config.data = qs.stringify(data);
  }

  return config;
};

const requestRefreshTrading = (baseURL = API_TRADING_URL): AxiosInstance => {
  const apiInstance = Axios.create({
    baseURL,
    headers: { accept: 'application/json' },
  });

  apiInstance.interceptors.request.use((config) => onRequest(config));

  return apiInstance;
};

export default requestRefreshTrading;
