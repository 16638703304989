import { API_EXODUS_URL } from 'constants/api';
import requestExodusConfig from 'config/requestExodus';
import { APIResponse } from '../../../@types/api-response';

const requestExodus = requestExodusConfig();

const getWSTradingKey = (): Promise<APIResponse> =>
  requestExodus.get(`${API_EXODUS_URL}/auth/websocket/key`);

export default {
  getWSKey: getWSTradingKey,
  getWSTradingKey,
};
