export interface IAccountType {
  account_name: string;
  account_number: string;
  bank_country_code: string;
  bank_logo: string;
  bank_swift_code: string;
  color_code: string;
  name: string;
}

export interface IAccount {
  personal: IAccountType;
  rdn: IAccountType;
}

export interface IAccountBalance {
  account_number: string;
  balance: number;
  portfolio_name: string;
}

export interface IBankAccount {
  account: IAccount;
  account_balances: IAccountBalance[];
  balance: number;
  client_citizenship_type: string;
  clientid: string;
  clientname: string;
  enable: boolean;
}

export enum BankAmendStatusEnums {
  AMEND_STATUS_UNSPECIFIED = 'AMEND_STATUS_UNSPECIFIED',
  AMEND_STATUS_IN_PROGRESS = 'AMEND_STATUS_IN_PROGRESS',
  AMEND_STATUS_REJECTED = 'AMEND_STATUS_REJECTED',
  AMEND_STATUS_COMPLETED = 'AMEND_STATUS_COMPLETED',
}

export interface IBankAmendInfo {
  status: {
    bank: BankAmendStatusEnums;
  };
  note: {
    bank: {
      title: string;
      description: string;
    };
  };
  user: {
    username: string;
  };
  amend_id: string;
}

export interface IBalance {
  withdrawable: number;
  transaction: number;
  pending: number;
  leverage_liability: number;
  net_withdrawable: number;
}

export interface IBalanceQueue {
  balance: number;
  formatted_date: string;
  title: string;
}

export interface ITradingBalanceDetail {
  balance: IBalance;
  settlement_schedules: IBalanceQueue[];
  legends: string[];
}

export interface IForeignBankCurrency {
  code: string;
  name: string;
}

export interface IForeignBankAmount {
  currency: IForeignBankCurrency;
  max: number;
  min: number;
}

export interface IForeignBankRules {
  amount: IForeignBankAmount;
  currencies: IForeignBankCurrency[];
  information: string;
}

export type WithdrawalTextType =
  | 'main_left_title_1'
  | 'main_left_title_2'
  | 'main_right_title_1'
  | 'main_right_title_2'
  | 'main_right_toogle'
  | 'main_right_button'
  | 'modal_confirmation_title_1'
  | 'modal_confirmation_title_2'
  | 'modal_confirmation_title_3'
  | 'modal_confirmation_title_4'
  | 'modal_confirmation_info'
  | 'modal_confirmation_button'
  | 'modal_pin_title'
  | 'modal_pin_forgot'
  | 'modal_pin_button'
  | 'modal_success_title'
  | 'modal_success_button'
  | 'modal_failed_title'
  | 'modal_balance_title'
  | 'modal_balance_transaction_label'
  | 'modal_balance_pending_transaction_label'
  | 'modal_balance_leverage_liability_label'
  | 'modal_balance_available'
  | 'error_below_balance'
  | 'error_over_balance'
  | 'error_below_balance_multi';

export interface IWithdrawalText {
  main_left_title_1: string;
  main_left_title_2: string;
  main_right_title_1: string;
  main_right_title_2: string;
  main_right_toogle: string;
  main_right_button: string;
  modal_confirmation_title_1: string;
  modal_confirmation_title_2: string;
  modal_confirmation_title_3: string;
  modal_confirmation_title_4: string;
  modal_confirmation_info: string | JSX.Element;
  modal_confirmation_button: string;
  modal_pin_title: string;
  modal_pin_forgot: string;
  modal_pin_button: string;
  modal_success_title: string;
  modal_success_button: string;
  modal_failed_title: string;
  modal_balance_title: string;
  modal_balance_transaction_label: string;
  modal_balance_pending_transaction_label: string;
  modal_balance_leverage_liability_label: string;
  modal_balance_available: string;
  error_below_balance: string;
  error_over_balance: string;
  error_below_balance_multi: string;
}

export interface IWithdrawalTextConfig {
  foreign: IWithdrawalText;
  local: IWithdrawalText;
}

export interface ICashWithdrawal {
  ocw_id?: number;
  status?: string;
}

export interface ITransferMethodAmount {
  min: number;
  max: number;
}

export interface ITransferMethodOperationTime {
  start: string;
  end: string;
}

export interface ITransferMethodOptionsEntity {
  name: string;
  code: string;
  fee: number;
  operation_time: ITransferMethodOperationTime;
  duration: string;
  description: string;
  amount: ITransferMethodAmount;
  is_real_time: boolean;
  daily_limit: {
    max: number;
    remaining: number;
    max_label: string;
  };
}

export interface ITransferMethodData {
  transfer_options?: ITransferMethodOptionsEntity[] | null;
}

export interface ITransferMethod {
  message: string;
  data: ITransferMethodData;
}

export interface ITransferOperationCheck {
  bankCode: string | 'BCA';
  transferType: 'ONL' | 'LLG' | 'RTG' | string;
}

export interface ITransferOperationResultStatusData {
  is_withdraw_in_operational_time: boolean;
}

export interface ITransferOperationResultStatus {
  data: ITransferOperationResultStatusData;
  message: string;
}

export interface IloadWithdrawalBalance {
  pin: string;
  amount: number;
  isForeign?: boolean;
  signature?: string;
  destinationCurrency?: string;
  swiftCode?: string;
  transferType?: string;
}

export interface IloadWithdrawalBalanceMultiAccount {
  pin: string;
  transactions: {
    account_no: string;
    amount: number;
  }[];
  isForeign?: boolean;
  signature?: string;
  destinationCurrency?: string;
  swiftCode?: string;
  transferType?: string;
}

export interface ITransferMethodDropdown {
  transferMethod: ITransferMethod['data']['transfer_options'];
  balanceInput: number;
  transferMethodValidator: (val: string) => void;
  setSelectedTransferMethod: React.Dispatch<
    React.SetStateAction<ITransferMethodOptionsEntity>
  >;
  selectedTransferMethod: ITransferMethodOptionsEntity;
  availableMinMaxTransfer: {
    min: number;
    max: number;
  };
  availableBalance: number;
}

export enum BalanceScopeEnum {
  ALL_RELATED_ACCOUNT = 'BALANCE_SCOPE_ALL_RELATED_ACCOUNT',
  SELECTED_ACCOUNT = 'BALANCE_SCOPE_SELECTED_ACCOUNT',
}

export interface ITradingBalanceDetailPayload {
  scope?: BalanceScopeEnum;
  accountNumber?: string;
}

export interface iTransaction {
  account_number: string;
  amount: number;
  account_name?: string;
}

interface IWithdrawalPayload {
  bank_account_number: string;
  validation_pin_token: string;
  signature?: string;
  bank_swift_code?: string;
  bank_country_code?: string;
  currency_code?: string;
  transfer_type?: string;
}

interface ITransactionPayload {
  account_no: string;
  amount: number;
}

export interface ISingleWithdrawalPayload extends IWithdrawalPayload {
  amount: number;
}

export interface IMultiWithdrawalPayload extends IWithdrawalPayload {
  transactions: ITransactionPayload[];
}
