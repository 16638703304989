import { AWS_AVATAR_BUCKET, isClient } from 'constants/app';
import { CLEVERTAP_EVENT_NAME } from 'constants/clevertap';
import { Crisp } from 'crisp-sdk-web';
import { clevertapEvent } from './clevertap';

export function crispReady(cb, timeout = 1000) {
  const { $crisp } = window;
  if ($crisp && timeout < 1) {
    return cb($crisp);
  }

  setTimeout(() => {
    if ($crisp) {
      cb($crisp);
    } else {
      crispReady(cb, timeout);
    }
  }, timeout);

  return null;
}

export const isCrispReady = () => isClient() && !!window.$crisp;

export function setNewSession(userData) {
  if (!isCrispReady()) return;

  // resetting session is necessary due to crisp autoloaded
  Crisp.session.reset();
  Crisp.chat.close();

  if (!userData) return;

  Crisp.user.setNickname(userData.username);
  Crisp.user.setEmail(userData.email);
  if (userData?.phone) {
    Crisp.user.setPhone(userData.phone);
  }
  Crisp.user.setAvatar(`${AWS_AVATAR_BUCKET}/${userData.avatar}`);
}

export function createNewSession({ email, fullname, id }) {
  window.CRISP_TOKEN_ID = id;
  setNewSession({ email, fullname });
}

export function destroyCurrentSession() {
  if (typeof window === 'undefined') return;

  delete window.CRISP_TOKEN_ID;

  crispReady(($crisp) => {
    if ($crisp && $crisp.push) $crisp.push(['do', 'session:reset']);
  }, 0);
}

export function resetSession() {
  if (!isCrispReady()) return;

  Crisp.session.reset();
}

export function hide() {
  if (!isCrispReady()) return;

  Crisp.chat.hide();
}

export function toggle() {
  if (!isCrispReady()) return;

  const isOpened = Crisp.chat.isChatOpened();

  if (isOpened) {
    Crisp.chat.close();
  } else {
    Crisp.chat.show();
    Crisp.chat.open();
  }
}

export function onLoaded() {
  if (!isCrispReady()) return;

  Crisp.session.onLoaded(() => {
    const isOpened = Crisp.chat.isChatOpened();
    if (!isOpened) {
      Crisp.chat.close();
    }
  });
}

export function onOpen() {
  if (!isCrispReady()) return;

  Crisp.chat.onChatOpened(() => {
    const unreadCount = document.querySelector('.intercom-unread-count');
    unreadCount.classList.remove('active');
  });
}

export function safe() {
  if (!isCrispReady()) return;

  Crisp.setSafeMode(true);
}

export function onReceiveMessage() {
  if (!isCrispReady()) return;

  Crisp.message.onMessageReceived(() => {
    const unreadCount = Crisp.chat.unreadCount();
    const unreadCountEl = document.querySelector('.intercom-unread-count');

    if (unreadCount > 0) {
      unreadCountEl.classList.add('active');
    }
  });
}

export function getSessionId() {
  if (!isCrispReady()) return null;

  const sessionId = Crisp.session.getIdentifier();
  return sessionId;
}

export function isChatOpened() {
  if (!isCrispReady()) return false;

  return Crisp.chat.isChatOpened();
}

export function isChatClosed() {
  if (!isCrispReady()) return false;

  const isOpened = Crisp.chat.isChatOpened();
  return !isOpened;
}

export const handleClickSupport = () => {
  if (!isCrispReady()) return;

  const isOpened = Crisp.chat.isChatOpened();

  if (isOpened) {
    Crisp.chat.close();
  } else {
    Crisp.chat.show();
    Crisp.chat.open();
    clevertapEvent(CLEVERTAP_EVENT_NAME.SIDE_MENU, {
      Action: 'support',
    });
  }
};
