import router from 'next/router';
import auth from 'utils/auth';
import chatApi from 'lib/api/chat';
import { PROJECT_FOLDER_PATH } from 'constants/app';
import { IPolicyParams } from './types';
import {
  FORMAT_TYPE_MESSAGE_CONTENT,
  LINK_TYPE_MESSAGE_CONTENT,
  NOTIF_TYPE,
} from './constant';

export const getNotifType = (room) =>
  NOTIF_TYPE.find(
    (dataNotif) =>
      dataNotif.type === room.type && dataNotif.state === room.state,
  );

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * snakelizeKeys
 * change keys from object to snake case
 * @param {Object} obj
 * @param {String} notAllowedObjects
 * @returns {Object}
 */
export const snakelizeKeys = (obj, notAllowedObjects = ['maskedText']) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => snakelizeKeys(v));
  }
  if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelToSnakeCase(key)]:
          notAllowedObjects.indexOf(key) < 0
            ? snakelizeKeys(obj[key])
            : obj[key],
      }),
      {},
    );
  }
  return obj;
};

const readFile = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });

const dataURItoBlob = (dataURI) => {
  const [mimeString, base64] = dataURI.split(',');
  const byteString = mimeString.includes('base64')
    ? atob(base64)
    : decodeURI(base64);
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString.split(':')[1].split(';')[0] });
};

const copyTextToClipboard = (text) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'text');
  const body = document.querySelector('body');
  input.value = text;
  body.appendChild(input);
  input.select();
  document.execCommand('copy');
  body.removeChild(input);
};

const bufferToFile = (file: any, type: string) => {
  const buffer = Buffer.from(file);
  const blob = new Blob([buffer], { type });
  return new File([blob], `temp.${type.split('/')[1]}`, { type });
};

const formatBytes = (bytes, decimals) => {
  if (typeof bytes === 'string') return bytes;

  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Chat Analytics helper for divide personal and group chat
 * @param personalChatAnalytics cb - analytics personal chat callback
 * @param groupChatAnalytics cb - analytics group chat callback
 */
const chatAnalytics = (
  personalChatAnalytics: () => void,
  groupChatAnalytics: () => void,
) => {
  const isPersonalChat = !router.asPath.includes('group-chat');
  if (isPersonalChat) {
    personalChatAnalytics();
  } else {
    groupChatAnalytics();
  }
};

const showTooltipUser = (localStorageKey) => {
  // @ts-ignore
  const { username } = auth.getUserAccess();

  const lsData = window?.localStorage?.getItem(localStorageKey);

  if (lsData && typeof JSON.parse(lsData) === 'object') {
    const userList: string[] = JSON.parse(
      window?.localStorage?.getItem(localStorageKey),
    );
    const isAvailable = userList.includes(username);
    if (isAvailable) {
      return false;
    }
    userList.push(username);
    localStorage.setItem(localStorageKey, JSON.stringify(userList));
    return true;
  }
  const userList = [username];
  localStorage.setItem(localStorageKey, JSON.stringify(userList));
  return true;
};

const getUploadPolicy = (params: IPolicyParams) =>
  new Promise((resolve, reject) => {
    chatApi
      .getPolicy(params)
      .then((res) => {
        const { data } = res.data;
        resolve(data);
      })
      .catch((err) => {
        reject(new Error(err));
      });
  });

const checkTagsForAnalytics = (text = '') => {
  let checkTagsPost = 'None';
  const countSymbol = (text.match(/\$([\w-]+)/g) || []).length;
  const countMention = (text.match(/@([\w-]+)/g) || []).length;

  if (countSymbol > 0 && countMention > 0) {
    checkTagsPost = 'Symbol & Account';
  } else if (countMention > 0) {
    checkTagsPost = 'Account';
  } else if (countSymbol > 0) {
    checkTagsPost = 'Symbol';
  }

  return checkTagsPost;
};

const REGEX_SYMBOL = /[$](?![0-9\-])([a-zA-Z0-9\-]+)/g;
const REGEX_USERNAME = /@(\w+)/g;
const REGEX_URL = /(https?:\/\/\S+)/g;

const getBetweenAsterisk = (fullString) => {
  const result = [];
  for (let i = 0; i < fullString.length; i++) {
    let temporaryString = '';
    if (fullString[i] === '*') {
      for (let j = i; j < fullString.length; j++) {
        if (
          j > 0 &&
          fullString[j] === '*' &&
          !fullString[j - 1].match(/\s/) &&
          temporaryString.length == 0
        ) {
          break;
        }

        // handle unclosed * by /n
        // eg
        // `*helo

        // *hello*
        if (
          fullString[j] === '*' &&
          temporaryString[0] === '*' &&
          fullString[j - 1].match(/\n/)
        ) {
          break;
        }
        temporaryString += fullString[j];
        if (
          (fullString[j] === '*' && fullString[j + 1] === ' ') ||
          (j === fullString.length - 1 && fullString[j] === '*') ||
          (fullString[j] === '*' && fullString[j + 1] === '\n')
        ) {
          break;
        }
      }
    }
    if (
      temporaryString.length > 0 &&
      temporaryString[0] === '*' &&
      temporaryString[temporaryString.length - 1] === '*' &&
      temporaryString.match('')
    ) {
      if (temporaryString.match(/\*/g).length > 1) {
        // avoid word that only contains "*""
        result.push({
          start: i,
          end: i + temporaryString.length - 1,
          raw: temporaryString,
          word: temporaryString.slice(1, -1),
        });
        i += temporaryString.length - 1;
      }
    }
  }
  return result;
};

export function handleMaskedContentAsterisk(
  content,
  masked_content,
  messageID,
) {
  const betweenAsterisks =
    content.indexOf('*') !== -1 ? getBetweenAsterisk(content) : []; // for bold
  let maskedContent = masked_content
    ? { ...masked_content, bold: true }
    : {
        masks: {},
        text: content,
      };
  let replace = maskedContent.text;
  const oriBetweenAsterisk =
    maskedContent.text.indexOf('*') !== -1
      ? getBetweenAsterisk(maskedContent.text)
      : [];
  for (const asterisk in betweenAsterisks) {
    if (betweenAsterisks[asterisk].word.length === 0) {
      // handle "**"
      break;
    }
    const identifier = `${messageID}-${asterisk}`;
    const renderSymbol =
      betweenAsterisks[asterisk].word.match(REGEX_SYMBOL) || [];
    const renderUsername =
      betweenAsterisks[asterisk].word.match(REGEX_USERNAME) || [];
    const renderURL = betweenAsterisks[asterisk].word.match(REGEX_URL) || [];
    const renderLinks = renderSymbol.concat(renderUsername, renderURL);

    // // render bolded links
    if (renderLinks.length > 0) {
      // render bold of basic text in between bolded sentence  that contains link
      // eg "*amazing, $BBCA is awesome*" => going to bold amazing, is awesome
      const basicTextInLink = betweenAsterisks[asterisk].word
        .split(/\s+/g)
        .filter((word) => {
          const noMatch =
            !word.match(REGEX_SYMBOL) && !word.match(REGEX_USERNAME);
          if (noMatch) {
            return word;
          }
        });
      const keys = Object.keys(maskedContent.masks);
      let temporaryString = betweenAsterisks[asterisk].word;
      let toReplaceFinal = betweenAsterisks[asterisk].word;
      for (const link in renderLinks) {
        let rendered = false;
        keys.map((key) => {
          oriBetweenAsterisk.map((ori, idx) => {
            const oriSplit = ori.word.split(' ');
            const temporaryStringSplit = temporaryString.split(' ');
            const indexOfKeyInOri = oriSplit.indexOf(`[%${key}%]`);
            if (
              renderLinks[link] === maskedContent.masks[key].text &&
              ori.word.includes(key)
            ) {
              rendered = true;
              if (indexOfKeyInOri > -1) {
                if (temporaryString[indexOfKeyInOri].includes('\n')) {
                  let temporaryToReplace = temporaryString[indexOfKeyInOri];
                  const indexofWhitespace =
                    temporaryToReplace.lastIndexOf('\n');
                  temporaryToReplace = temporaryToReplace.slice(
                    0,
                    indexofWhitespace,
                  );
                  temporaryToReplace += '\n'.repeat(indexofWhitespace + 1);
                  temporaryToReplace += `[%${key}%]`;
                  temporaryStringSplit[indexOfKeyInOri] = temporaryToReplace;
                } else {
                  temporaryStringSplit[indexOfKeyInOri] = `[%${key}%]`;
                }
                temporaryString = temporaryStringSplit.join(' ');
                toReplaceFinal = temporaryStringSplit.join(' ');
              } else {
                temporaryString = temporaryString.replace(
                  `${renderLinks[link]}`,
                  `[%${key}%]`,
                );
                toReplaceFinal = temporaryString;
              }
              maskedContent = {
                masks: {
                  ...maskedContent.masks,
                  [key]: {
                    ...maskedContent.masks[key],
                    format: FORMAT_TYPE_MESSAGE_CONTENT.TEXT_FORMAT_BOLD,
                  },
                },
              };
              replace = replace.replace(`*${toReplaceFinal}*`, temporaryString);
              maskedContent.text = replace;
            }
          });
        });

        // loading / failed state for links
        if (keys.length === 0 || !rendered) {
          maskedContent = {
            ...maskedContent,
            masks: {
              ...maskedContent.masks,
              [`${identifier}-${asterisk}-${link}-link`]: {
                text: renderLinks[link],
                format: FORMAT_TYPE_MESSAGE_CONTENT.TEXT_FORMAT_BOLD,
                link: {
                  ref: {
                    symbol: renderLinks[link],
                  },
                  type: checkWordLinkType(renderLinks[link]),
                },
              },
            },
          };
          temporaryString = temporaryString.replace(
            renderLinks[link],
            `[%${identifier}-${asterisk}-${link}-link%]`,
          );
          rendered = true;
        }
      }
      for (const basicText in basicTextInLink) {
        maskedContent = {
          ...maskedContent,
          masks: {
            ...maskedContent.masks,
            [`${identifier}-${asterisk}-${basicText}`]: {
              format: FORMAT_TYPE_MESSAGE_CONTENT.TEXT_FORMAT_BOLD,
              text: basicTextInLink[basicText],
            },
          },
        };
        temporaryString = temporaryString.replace(
          `${basicTextInLink[basicText]}`,
          `[%${identifier}-${asterisk}-${basicText}%]`,
        );
      }
      if (typeof messageID === 'string') {
        replace = replace.replace(`*${toReplaceFinal}*`, temporaryString);
      } else {
        replace = replace.replace(`${toReplaceFinal}`, temporaryString);
      }
      maskedContent.text = replace;
    } else {
      maskedContent = {
        ...maskedContent,
        masks: {
          ...maskedContent.masks,
          [`${identifier}-${asterisk}`]: {
            format: FORMAT_TYPE_MESSAGE_CONTENT.TEXT_FORMAT_BOLD,
            text: betweenAsterisks[asterisk].word,
          },
        },
      };
      replace = replace.replace(
        `*${betweenAsterisks[asterisk].word}*`,
        `[%${identifier}-${asterisk}%]`,
      );
      maskedContent.text = replace;
    }
  }
  return maskedContent;
}

// check if word $BBCA, @username is emitten or username
export const checkWordLinkType = (word) => {
  if (word.match(REGEX_SYMBOL)) {
    return LINK_TYPE_MESSAGE_CONTENT.LINK_TYPE_SYMBOL;
  }

  if (word.match(REGEX_USERNAME)) {
    return LINK_TYPE_MESSAGE_CONTENT.LINK_TYPE_USER;
  }

  if (word.match(REGEX_URL)) {
    return LINK_TYPE_MESSAGE_CONTENT.LINK_TYPE_KNOWN_URL;
  }
  return null;
};

export const removeFormat = (rawCaption: string) => {
  const cleanedMD = [
    // bold
    /\*([a-zA-Z0-9\s]+)\*(?!\r?\n\*)/g,
    // underline
    /__(.*?)__/g,
  ].reduce(
    (prev, current) =>
      typeof prev === 'string' ? prev.replace(current, '$1') : prev,
    rawCaption,
  );

  if (typeof cleanedMD === 'string') {
    return (
      cleanedMD
        // replace the backslashes that contains formats
        .replace(/\\/g, '\\')
        .replace(/\\\*/g, '*')
        .replace(/\\_/g, '_')
        .replace(/\\`/g, '`')
        // replace the html encoded
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );
  }

  return cleanedMD;
};

// removing the asterisk that is responsible to make word bold
// usable in reply preview(bubble and compose)
// "*bold* one" -> bold one
const removeAsteriskFromCaption = (rawCaption) => {
  let resultCaption = rawCaption;
  const betweenAsterisks = getBetweenAsterisk(rawCaption);
  if (betweenAsterisks.length > 0) {
    betweenAsterisks.forEach((el) => {
      resultCaption = resultCaption.replace(el.raw, el.word);
    });
  }
  return resultCaption;
};

export {
  readFile,
  dataURItoBlob,
  copyTextToClipboard,
  bufferToFile,
  formatBytes,
  chatAnalytics,
  showTooltipUser,
  getUploadPolicy,
  checkTagsForAnalytics,
  getBetweenAsterisk,
  removeAsteriskFromCaption,
};
