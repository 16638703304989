import { DATA_WS_LOGGER, SEND_MESSAGE_TYPE } from 'features/chat/constant';
import { sendMessageWsSocial, wsLogger } from '../utils';

/**
 * subscribeToChatRooms
 */
export function subscribeToRoomList() {
  return () => {
    wsLogger({
      module: DATA_WS_LOGGER.MODULE_NAME,
      action: DATA_WS_LOGGER.ACTION.SUBSCRIBE_TO_ROOM_LIST,
    });
    const payload = {
      type: SEND_MESSAGE_TYPE.TYPE_SUBSCRIBE,
      payload: {
        channel: {
          roomlist: true,
        },
      },
    };
    sendMessageWsSocial(payload);
  };
}

/**
 * subscribeToChatRooms
 */
export function unSubscribeToRoomList() {
  return () => {
    wsLogger({
      module: DATA_WS_LOGGER.MODULE_NAME,
      action: DATA_WS_LOGGER.ACTION.UNSUBSCRIBE_TO_ROOM_LIST,
    });
    const payload = {
      type: SEND_MESSAGE_TYPE.TYPE_UNSUBSCRIBE,
      payload: {
        channel: {
          roomlist: true,
        },
      },
    };
    sendMessageWsSocial(payload);
  };
}
