import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import multiPortoAPI from 'lib/api/multi-porto';
import securitiesAPI from 'lib/api/securities';
import useModalStore from 'features/multi-porto/stores/modal.store';

import {
  GetSubAccountResponse,
  GetPurposeResponse,
  CreateAccountPayload,
  RenameAccountPayload,
  MoveCashPayload,
  MoveStockPayload,
  GetWithdrawableResponse,
  GetTradingBalanceResponse,
  GetTransferableStockResponse,
  iTradingBalance,
} from 'global/Securities/types/api/multiPorto';
import { BalanceScopeEnum } from 'features/securities/withdrawal/types';
import { getErrorConfig } from 'config/requestTrading/utils';

interface iDefaultData<T> {
  isLoading?: boolean;
  message?: string;
  data?: T;
  error?: string;
}

type PortfolioStore = {
  activeAccount: string;
  subAccountsData: iDefaultData<GetSubAccountResponse>;
  subAccountsPurposeData: iDefaultData<GetPurposeResponse>;
  withDrawableData: iDefaultData<GetWithdrawableResponse>;
  transferableStocks: iDefaultData<GetTransferableStockResponse>;
  tradingBalanceData: iDefaultData<iTradingBalance>;

  createPortfolioForm: CreateAccountPayload;
  renamePortfolioForm: RenameAccountPayload;
  moveCashForm: MoveCashPayload;
  moveStockForm: MoveStockPayload;

  getSubAccounts: () => Promise<GetSubAccountResponse>;
  getPurposes: () => Promise<GetPurposeResponse>;
  getWithdrawable: (scope?: BalanceScopeEnum, account?: string) => Promise<GetWithdrawableResponse>;
  getTransferableStocks: (account: string) => Promise<GetTransferableStockResponse>;
  getTradingBalance: () => Promise<GetTradingBalanceResponse>;
  resetTransferableStocks: () => void;
  resetWithdrawable: () => void;
  postCreatePorto: (payload: CreateAccountPayload) => Promise<void>;
  postRenamePorto: (payload: RenameAccountPayload) => Promise<void>;
  postMoveCash: (payload: MoveCashPayload) => Promise<void>;
  postMoveStock: (payload: MoveStockPayload) => Promise<void>;
  setActiveAccount: (account: string) => void;
  setPortfolioForm: (form: CreateAccountPayload) => void;
  setRenamePortfolioForm: (form: RenameAccountPayload) => void;
  setMoveCashForm: (form: MoveCashPayload) => void;
  setMoveStockForm: (form: MoveStockPayload) => void;
  resetMoveCashForm: () => void;
  resetMoveStockForm: () => void;
  resetState: () => void;
};

const UNAUTHORIZED = 401;

const defaultInitialState = {
  isLoading: false,
  error: '',
};

const subAccountsInitialState: iDefaultData<GetSubAccountResponse> = {
  ...defaultInitialState,
  data: {
    sub_accounts: [],
    can_create_new_sub_account: false,
  },
};

const subAccountsPurposeInitialState: iDefaultData<GetPurposeResponse> = {
  ...defaultInitialState,
  data: {
    purposes: [],
  },
};

const withDrawableInitialState: iDefaultData<GetWithdrawableResponse> = {
  ...defaultInitialState,
  data: {
    balance: {
      withdrawable: 0,
      transaction: 0,
      pending: 0,
      leverage_liability: 0,
      net_withdrawable: 0,
    },
    settlement_schedules: [],
    legends: [],
  },
};

const tradingBalanceInitialState: iDefaultData<iTradingBalance> = {
  ...defaultInitialState,
  data: {
    balance: 0,
    equity: 0,
  },
};

const portfoliosStockInitialState: iDefaultData<GetTransferableStockResponse> = {
  ...defaultInitialState,
  data: {
    stocks: [],
  },
};

const createPortfolioFormInitialState: CreateAccountPayload = {
  portfolio_name: '',
  tnc: {
    feature_id: '',
    version: 0,
  },
  purpose: '',
};

const renamePortfolioFormInitialState: RenameAccountPayload = {
  target_account_number: '',
  new_portfolio_name: '',
};

const moveCashFormInitialState: MoveCashPayload = {
  from_acc_no: '',
  to_acc_no: '',
  amount: 0,
};

const moveStockFormInitialState: MoveStockPayload = {
  from_acc_no: '',
  to_acc_no: '',
  stocks_data: [],
};

const initialState = {
  activeAccount: '',
  subAccountsData: subAccountsInitialState,
  subAccountsPurposeData: subAccountsPurposeInitialState,
  withDrawableData: withDrawableInitialState,
  transferableStocks: portfoliosStockInitialState,
  tradingBalanceData: tradingBalanceInitialState,

  createPortfolioForm: createPortfolioFormInitialState,
  renamePortfolioForm: renamePortfolioFormInitialState,
  moveCashForm: moveCashFormInitialState,
  moveStockForm: moveStockFormInitialState,
};

const useMultiPortfolioStore = create<PortfolioStore>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,

        getSubAccounts: async () => {
          const currentData = get()?.subAccountsData;
          set({ subAccountsData: { ...currentData, isLoading: true } });
          try {
            const { data } = await multiPortoAPI.getSubAccounts();
            set({
              subAccountsData: {
                ...currentData,
                data: data.data,
                error: '',
                isLoading: false,
              },
            });
            return data.data;
          } catch (error) {
            set({
              subAccountsData: {
                ...currentData,
                error,
                isLoading: false,
              },
            });
            if (error.status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }
            return error;
          }
        },
        getPurposes: async () => {
          const currentData = get()?.subAccountsPurposeData;
          set({ subAccountsPurposeData: { ...currentData, isLoading: true } });
          try {
            const { data } = await multiPortoAPI.getPurposes();
            set({
              subAccountsPurposeData: {
                ...currentData,
                data: data.data,
                error: '',
                isLoading: false,
              },
            });

            return data.data;
          } catch (error) {
            set({
              subAccountsPurposeData: {
                ...subAccountsPurposeInitialState,
                error,
                isLoading: false,
              },
            });
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }
            return error;
          }
        },
        getWithdrawable: async (scope, accountNumber) => {
          const currentData = get()?.withDrawableData;
          set({ withDrawableData: { ...currentData, isLoading: true } });
          try {
            const params = {
              scope,
              accountNumber,
            };
            const { data } = await securitiesAPI.getTradingBalanceDetail(
              params,
            );
            set({
              withDrawableData: {
                ...currentData,
                data: data.data,
                error: '',
                isLoading: false,
              },
            });

            return data.data;
          } catch (error) {
            set({
              withDrawableData: {
                ...withDrawableInitialState,
                error,
                isLoading: false,
              },
            });
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }
            return error;
          }
        },
        getTransferableStocks: async (account) => {
          const currentData = get()?.transferableStocks;
          set({ transferableStocks: { ...currentData, isLoading: true } });
          try {
            const { data } = await multiPortoAPI.getTransferableStocks(account);
            set({
              transferableStocks: {
                ...currentData,
                data: data.data,
                error: '',
                isLoading: false,
              },
            });

            return data.data;
          } catch (error) {
            set({
              transferableStocks: {
                ...portfoliosStockInitialState,
                error,
                isLoading: false,
              },
            });
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }
            return error;
          }
        },
        getTradingBalance: async () => {
          const currentData = get()?.tradingBalanceData;
          set({ tradingBalanceData: { ...currentData, isLoading: true } });
          try {
            const { data } = await multiPortoAPI.getTradingBalance();
            const { aggregated_portfolio_summary: summary } = data.data || {};

            set({
              tradingBalanceData: {
                ...currentData,
                data: {
                  balance: summary.trading.balance,
                  equity: summary.equity,
                },
                error: '',
                isLoading: false,
              },
            });

            return data.data;
          } catch (error) {
            set({
              tradingBalanceData: {
                ...tradingBalanceInitialState,
                error,
                isLoading: false,
              },
            });
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }
            return error;
          }
        },
        resetTransferableStocks: () =>
          set({ transferableStocks: portfoliosStockInitialState }),
        resetWithdrawable: () =>
          set({ withDrawableData: withDrawableInitialState }),
        postCreatePorto: async (payload) => {
          try {
            const { data } = await multiPortoAPI.postCreatePorto(payload);
            const udpatedSubAccounts = data.data.sub_accounts;
            const newSubAccountsLength = udpatedSubAccounts.length;

            set({
              subAccountsData: {
                ...get()?.subAccountsData,
                data: {
                  ...get()?.subAccountsData.data,
                  sub_accounts: udpatedSubAccounts,
                  ...{ can_create_new_sub_account: newSubAccountsLength < 4 },
                },
              },
            });

            return true;
          } catch (error) {
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }

            return error;
          }
        },
        postRenamePorto: async (payload) => {
          try {
            const { data } = await multiPortoAPI.postRenamePorto(payload);
            const udpatedSubAccounts = data.data.sub_accounts;

            set({
              subAccountsData: {
                ...get()?.subAccountsData,
                data: {
                  ...get()?.subAccountsData.data,
                  sub_accounts: udpatedSubAccounts,
                },
              },
            });

            return true;
          } catch (error) {
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }

            return error;
          }
        },
        postMoveCash: async (payload) => {
          try {
            await multiPortoAPI.postMoveCash(payload);
            return true;
          } catch (error) {
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }

            return error;
          }
        },
        postMoveStock: async (payload) => {
          try {
            await multiPortoAPI.postMoveStock(payload);
            return true;
          } catch (error) {
            const { status } = getErrorConfig(error);
            if (status === UNAUTHORIZED) {
              useModalStore.getState().closeModal();
            }

            return error;
          }
        },
        setActiveAccount: (account) => set({ activeAccount: account }),
        setPortfolioForm: (form) => set({ createPortfolioForm: form }),
        setRenamePortfolioForm: (form) => set({ renamePortfolioForm: form }),
        setMoveCashForm: (form) => set({ moveCashForm: form }),
        setMoveStockForm: (form) => set({ moveStockForm: form }),
        resetMoveCashForm: () =>
          set({ moveCashForm: moveCashFormInitialState }),
        resetMoveStockForm: () =>
          set({ moveStockForm: moveStockFormInitialState }),
        resetState: () => {
          set(initialState);
        },
      }),
      {
        name: 'multi-portfolio-storage',
        getStorage: () => localStorage,
        partialize: (state) => ({
          subAccountsData: state.subAccountsData,
          activeAccount: state.activeAccount,
          subAccountsPurposeData: state.subAccountsPurposeData,
          tradingBalanceData: state.tradingBalanceData,
        }),
      },
    ),
  ),
);

export default useMultiPortfolioStore;
