import { DATA_WS_LOGGER, SEND_MESSAGE_TYPE } from 'features/chat/constant';
import { sendMessageWsSocial, wsLogger } from '../utils';

/**
 * subscribeToChatRooms
 * @param {Array} chatroomIds
 * @returns
 */
export function subscribeToChatRooms(chatroomIds = []) {
  return () => {
    if (chatroomIds.length > 0) {
      wsLogger({
        module: DATA_WS_LOGGER.MODULE_NAME,
        action: DATA_WS_LOGGER.ACTION.SUBSCRIBE_TO_CHAT_ROOMS,
        payload: chatroomIds,
      });
      const payload = {
        type: SEND_MESSAGE_TYPE.TYPE_SUBSCRIBE,
        payload: {
          channel: {
            chatroom: chatroomIds,
          },
        },
      };
      sendMessageWsSocial(payload);
    }
  };
}

/**
 * unSubscribeToChatRooms
 * @param {Array} chatroomIds
 * @returns
 */
export function unSubscribeToChatRooms(chatroomIds = []) {
  return () => {
    if (chatroomIds.length > 0) {
      wsLogger({
        module: DATA_WS_LOGGER.MODULE_NAME,
        action: DATA_WS_LOGGER.ACTION.UNSUBSCRIBE_TO_CHAT_ROOMS,
        payload: chatroomIds,
      });
      const payload = {
        type: SEND_MESSAGE_TYPE.TYPE_UNSUBSCRIBE,
        payload: {
          channel: {
            chatroom: chatroomIds,
          },
        },
      };
      sendMessageWsSocial(payload);
    }
  };
}
