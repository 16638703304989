import requestTradingConfig from 'config/requestTrading';

import { API_TRADING_URL_V2 } from 'constants/api';

import {
  GetSubAccountResponse,
  GetPurposeResponse,
  CreateAccountPayload,
  CreateRenameResponse,
  RenameAccountPayload,
  MoveCashPayload,
  GetTradingBalanceResponse,
  GetTransferableStockResponse,
  MoveStockPayload,
} from 'global/Securities/types/api/multiPorto';
import { APIResponse } from '../../../@types/api-response';

const requestTrading = requestTradingConfig(API_TRADING_URL_V2, false, false);

const getSubAccounts = (): Promise<APIResponse<GetSubAccountResponse>> =>
  requestTrading.get(`${API_TRADING_URL_V2}/sub-account/list`);

const getPurposes = (): Promise<APIResponse<GetPurposeResponse>> =>
  requestTrading.get(`${API_TRADING_URL_V2}/sub-account/purposes`);

const getTransferableStocks = (
  accountNumber: string,
): Promise<APIResponse<GetTransferableStockResponse>> => {
  const query = new URLSearchParams({ target_account_number: accountNumber });
  return requestTrading.get(
    `${API_TRADING_URL_V2}/portfolio/v2/transferable-stocks?${query}`,
  );
};

const getTradingBalance = (): Promise<APIResponse<GetTradingBalanceResponse>> =>
  requestTrading.get(`${API_TRADING_URL_V2}/portfolio/v2/summary`);

const postCreatePorto = (
  payload: CreateAccountPayload,
): Promise<APIResponse<CreateRenameResponse>> =>
  requestTrading.post(`${API_TRADING_URL_V2}/sub-account/create`, payload);

const postSwitchAccount = (accountNumber: string): Promise<APIResponse> =>
  requestTrading.post(`${API_TRADING_URL_V2}/auth/account/switch`, {
    account_number: accountNumber,
  });

const postRenamePorto = (
  payload: RenameAccountPayload,
): Promise<APIResponse<CreateRenameResponse>> =>
  requestTrading.post(`${API_TRADING_URL_V2}/sub-account/rename`, payload);

const postMoveCash = (payload: MoveCashPayload): Promise<APIResponse> =>
  requestTrading.post(
    `${API_TRADING_URL_V2}/intraservice/multi-portfolio/v1/move-cash`,
    payload,
  );

const postMoveStock = (payload: MoveStockPayload): Promise<APIResponse> =>
  requestTrading.post(
    `${API_TRADING_URL_V2}/intraservice/multi-portfolio/v1/move-stock`,
    payload,
  );

export default {
  getSubAccounts,
  getPurposes,
  getTransferableStocks,
  getTradingBalance,
  postCreatePorto,
  postSwitchAccount,
  postRenamePorto,
  postMoveCash,
  postMoveStock,
};
