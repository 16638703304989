import { HYDRATE } from 'next-redux-wrapper';
import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';

// Auth
import auth from 'lib/models/auth';
import authData from 'utils/auth';
import { logout } from 'lib/api/authNext';
import router from 'next/router';
import { destroyCurrentSession } from 'utils/crisp';
import authCookie from 'utils/authCookie';
import { isClient } from 'constants/app';
import mainLayoutStore from 'global/MainLayout/store';

import { effects as chatEffects } from 'lib/entities/chat/slice';
import { IS_LOGGED_IN_LOCALSTORAGE } from 'features/login/constants';
import { clearIntervalPingWSSocial } from 'global/WebSocketV2/slice';
import {
  removeFeatureFlagDebugWsSocial,
  storeErrorNoReconnectionWsSocial,
} from 'utils/socketSocialLocalStorage';
import {
  DATA_WS_LOGGER,
  WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION,
} from 'features/chat/constant';
import { unSubscribeToRoomList } from 'global/WebSocketV2/channels/roomList';
import { unSubscribeToChatRooms } from 'global/WebSocketV2/channels/chatRoom';
import { wsLogger } from 'global/WebSocketV2/utils';
import useSecuritiesCredentialStore from 'global/Securities/stores/useSecuritiesCredentialStore';

/**
 * getAccessToken
 * to is valid current token in cookies
 * @param {} null
 * @returns boolean
 */
const isValidAccessToken = () => {
  const parsedCookie = authData.getToken(null);

  if (parsedCookie) {
    const {
      accessToken: { token },
    } = parsedCookie;

    return !!token;
  }

  return false;
};

/**
 * disconnectWebSocketSocial
 * @param {Array} subscribedRoomsId
 */
const disconnectWebSocketSocial = (dispatch, subscribedRoomsId) => {
  wsLogger({
    module: DATA_WS_LOGGER.MODULE_NAME,
    action: DATA_WS_LOGGER.ACTION.DISCONNECT,
  });

  // reset state active room id
  dispatch(chatEffects.resetActiveRoomId());
  // unsubscribe ws social: chatrooms
  dispatch(unSubscribeToChatRooms(subscribedRoomsId));
  // unsubscribe ws social: roomList
  dispatch(unSubscribeToRoomList());
  // clear inverval ping WS Social
  clearIntervalPingWSSocial();

  // allowed reconnection ws social
  storeErrorNoReconnectionWsSocial(
    WEBSOCKET_WS_SOCIAL_ERROR_NO_RECONNECTION.ALLOWED,
  );

  removeFeatureFlagDebugWsSocial();
};

// initial state
export const initialState = {
  data: auth.schema,
  // check token from cookies
  isLoggedIn: isClient() && Boolean(localStorage?.at),
  error: null,
  message: '',
};

// Selectors -------------------------------------------------------------------
export const selectors = createSelector(
  (state) => state.entities.credentials,
  (state) => ({
    data: state.data,
    isLoggedIn: state.isLoggedIn,
    isLoading: state.isLoading,
    error: state.error,
    message: state.message,
  }),
);

export const userIdSelector = createSelector(
  (state) => state.entities.credentials.data,
  (credentials) => credentials?.access_user?.id,
);

export const isLoggedInSelector = createSelector(
  (state) => state.entities.credentials.isLoggedIn,
  (isLoggedIn) => isLoggedIn,
);

export const credentialSelector = createSelector(
  (state) => state.entities.credentials.data,
  (data) => data,
);

// Actions ---------------------------------------------------------------------
// types
const CONTEXT = '@entities/credentials';

const actionType = {
  LOGOUT: `${CONTEXT}/logout`,
  REFRESH: `${CONTEXT}/refresh`,
};

// Reducer ---------------------------------------------------------------------
const reducers = {
  loadCredentialsExodus: (state, action) => {
    const { user, access, refresh, onesignal_hash, support } = action.payload;
    const accessUser = {
      username: user.username,
      email: user.email,
      fullname: user.fullname,
      avatar: user.avatar,
      password: Number(user.has_password_been_set),
      verified: Number(user.is_verified),
      privilege: user.privilege.code,
      id: user.id,
      watchlist_id: user.watchlist_id,
      country: user.country,
      exchange: user.exchange,
    };
    const parsedToken = {
      access: {
        token: access.token,
        expired_at: new Date(access.expired_at).getTime(),
      },
      refresh: {
        token: refresh.token,
        expired_at: new Date(refresh.expired_at).getTime(),
      },
    };
    authData.saveToken({
      accessToken: parsedToken.access.token,
      accessTokenExp: parsedToken.access.expired_at,
      refreshToken: parsedToken.refresh.token,
      refreshTokenExp: parsedToken.refresh.expired_at,
      accessUser,
    });
    const data = {
      ...state,
      access_token: parsedToken.access.token,
      access_token_exp: parsedToken.access.expired_at,
      refresh_token: parsedToken.refresh.token,
      refresh_token_exp: parsedToken.refresh.expired_at,
      access_user: accessUser,
      onesignal_hash,
      support: {
        id: support ? support.id : '',
      },
    };
    state.data = data;
    state.isLoggedIn = true;
  },
  loadCredentialsToken: (state, action) => {
    const { access, refresh } = action.payload;

    const parsedAccessExpired = new Date(access.expired_at).getTime() / 1000;
    const parsedRefreshExpired = new Date(refresh.expired_at).getTime() / 1000;

    auth.saveToken({
      accessToken: access.token,
      accessTokenExp: parsedAccessExpired,
      refreshToken: refresh.token,
      refreshTokenExp: parsedRefreshExpired,
    });
  },
};

// effects
export const effects = {
  // logout --------------------------------------------------------------------
  logout: createAsyncThunk(
    actionType.LOGOUT,
    async (_, { dispatch, getState }) => {
      try {
        const response = await logout();

        if (response.data.loggedOut) {
          localStorage.setItem(IS_LOGGED_IN_LOCALSTORAGE, false);

          const subscribedRoomsId = getState()?.entities?.chat?.activeRoomId;

          // unsubscribe & disconnect ws social
          disconnectWebSocketSocial(dispatch, subscribedRoomsId);

          await authCookie.clearCookies();
          mainLayoutStore.setState({ errorCode: null });
          destroyCurrentSession();
          authData.destroyToken();
          await useSecuritiesCredentialStore.getState().loadLogout();

          return {
            message: 'Logout success',
          };
        }

        return response.data;
      } catch (error) {
        return {
          message: 'Logout attempt failed!',
        };
      }
    },
  ),
};

// Extra Reducer ---------------------------------------------------------------
const extraReducers = {
  [HYDRATE]: (draft, action) => {
    draft.data = action.payload.auth.credentials.data;
    draft.isLoggedIn =
      action.payload.auth.credentials.isLoggedIn ||
      (isClient() && Boolean(localStorage?.at));
    draft.isLoading = action.payload.auth.credentials.isLoading;
    draft.error = action.payload.auth.credentials.error;
    draft.message = action.payload.auth.credentials.message;
  },
  // logout reducer ------------------------------------------------------------
  [effects.logout.fulfilled]: (draft, action) => {
    const { message } = action.payload;
    draft.isLoggedIn = false;
    draft.message = message;
    draft.data = null;
    draft.isLoading = false;
    draft.error = null;
    router.push('/login');
  },
};

// Slice -----------------------------------------------------------------------
const credentialsSlice = createSlice({
  name: 'credentials',
  initialState,
  reducers,
  extraReducers,
});

export default credentialsSlice;
