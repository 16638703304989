/**
 * Parse File Extension
 * @param {string} url - URL Of File
 * @returns {string} extension of file url
 */
const parseFileExtension = (url) => {
  const extension = url?.match(/\.[0-9a-z]+$/i);
  if (extension) {
    return extension[0];
  }
  return '';
};

function uniq(a, key) {
  const seen = new Set();
  return a.filter((item) => {
    const k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

export { uniq, parseFileExtension };
