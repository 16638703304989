import axios from 'axios';
import { PROJECT_FOLDER } from 'constants/app';
import auth from 'utils/auth';
import handler from './APIErrorMessage';

const { APIExodusErrorHandler } = handler;

function requestNext(isShowAlertMessage = true) {
  const apiInstance = axios.create({
    baseURL: `/${PROJECT_FOLDER || ''}`,
    headers: {
      accept: 'application/json',
    },
  });

  apiInstance.interceptors.request.use(
    (request) => {
      // Custom Authorization Header Exist
      if (request.headers.Authorization) {
        return request;
      }

      // Default Authorization
      const parsedToken = auth.getToken();
      const token = parsedToken?.accessToken?.token;
      if (token) {
        // eslint-disable-next-line no-param-reassign
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    },
    (error) => Promise.reject(error),
  );

  apiInstance.interceptors.response.use(
    async (response) => response,
    (error) => {
      if (!error?.response || !isShowAlertMessage) {
        return Promise.reject(error);
      }

      const { status, data } = error.response;

      let message = data?.message;
      let errorType = data?.error_type;
      // the real error message is unparsed in `message`
      if (!errorType) {
        const parsedMessage = JSON.parse(message);
        message = parsedMessage.message;
        errorType = parsedMessage.error_type;
      }
      APIExodusErrorHandler(status, errorType, message);

      return Promise.reject(error);
    },
  );
  return apiInstance;
}

export default requestNext;
