const queue = [];
const DELAY_INCOMING_MESSAGE_TIME = 1000;

function getFromQueue() {
  return queue.shift();
}

export const insertQueue = async (msg: any) => {
  await queue.push(msg);
  return new Promise((res) => {
    return setTimeout(() => {
      const data = getFromQueue();
      res(data);
    }, DELAY_INCOMING_MESSAGE_TIME);
  });
};
